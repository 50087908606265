import React, {useState, useEffect} from 'react'

import { getAll_Categorias } from "../services/Categorias";

import { get_Token } from "../helpers/getToken";

import Loading from "../components/Loading/Loading";

import Navegacion from "../components/Navegacion/Navegacion";

import { TituloSeccion, ItemSeccion } from "../components/Titulos/Titulos";
import { ContentSeccion, LineSeparadora } from "../components/Content/Estilos";
import { CardsCategorias, SinCategorias } from "../components/CardsCategorias/CardsCategorias";

const InitData = async () => {
  const {data} = await getAll_Categorias();

  if(data){
    const categorias = data.data
    let activas = []
    let inactivas = []
      
    for (const unaCategoria of categorias) {
      if(unaCategoria.Activo === true){
        activas.push(unaCategoria)
      }
      if(unaCategoria.Activo === false){
        inactivas.push(unaCategoria)
      }
    }
  
    return {
      activas,
      inactivas
    }
  }

  return null

}

const Categorias = ({title}) => {

    const [columns, setColummns] = useState(false)
    const [loading, setLoading] = useState(true)
    const [categoriasActivas, setCategoriasActivas] = useState([])
    const [categoriasInactivas, setCategoriasInactivas] = useState([])

    useEffect(() => {
      get_Token()
      const getData = async () => {
        const data = await InitData()

        if(data){
          setCategoriasActivas(data.activas)
          setCategoriasInactivas(data.inactivas)
        }else{
          setCategoriasActivas([])
          setCategoriasInactivas([])
        }
 
        setLoading(false)
      }
      getData()

    }, [loading])




    return (
      <>
      { loading === true ?
          <Loading />
        :
        <div className="row">
  
          <div className={!columns ? 'col-md-2 layout-general' : 'col-md-1 layout-general'}>
            <Navegacion hideSide={setColummns} active='/Categorias' />
          </div>
  
          <div className={!columns ? 'col-md-10 layout-general' : 'col-md-11 layout-general'}>

            <TituloSeccion title={title} link='/Inicio' texto='Volver al Inicio' />
            
            <ContentSeccion>
              <ItemSeccion title='Categorias Activas' />
              {
                categoriasActivas.length === 0 ?
                  <SinCategorias 
                    texto='No posees categorias Activas.'
                  />
                :
                <div className='row mt-4 mb-4'>
                  {
                    categoriasActivas.map(unaCategoria => (
                      <CardsCategorias categoria={unaCategoria} key={unaCategoria.Id} />
                    ))
                  }
                </div>
              }

              <LineSeparadora />

              <ItemSeccion title='Categorias Inactivas' />

              {
                categoriasInactivas.length === 0 ?
                  <SinCategorias 
                    texto='No posees categorias Inactivas.'
                  />
                :
                  <div className='row mt-4 mb-4'>
                    {
                      categoriasInactivas.map(unaCategoria => (
                        <CardsCategorias categoria={unaCategoria} key={unaCategoria.Id} />
                      ))
                    }
                  </div>
              }
              
            </ContentSeccion>
            
          </div>
  
        </div>
      }
      
      </>

     
    )

}

export default Categorias