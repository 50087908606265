import React from 'react'

const TitulosColumnaUsuarios = () => {
  return (
    <div className="row text-center tablaUsuario p-4">
                    
        <div className="col-md-2">
            <p className="tituloColumnaUsuario">
                Imagen
            </p>
        </div>
    
        <div className="col-md-2">
            <p className="tituloColumnaUsuario">
               Nombre Producto
            </p>
        </div>

        <div className="col-md-2">
            <p className="tituloColumnaUsuario">
                Precio
            </p>
        </div>

        <div className="col-md-2">
            <p className="tituloColumnaUsuario">
                Stock
            </p>
        </div>

        <div className="col-md-2">
            <p className="tituloColumnaUsuario">
                N° Sicoi
            </p>
        </div>

        <div className="col-md-1">
            <p className="tituloColumnaUsuario">
                Estado
            </p>
        </div>

        <div className="col-md-1">
            <p className="tituloColumnaUsuario">
                Más
            </p>
        </div>
    </div>
  )
}

export default TitulosColumnaUsuarios