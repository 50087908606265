import styled from 'styled-components';

export const ContentSeccion = styled.div`
    padding: 50px 50px;
`

export const LineSeparadora = styled.div`
    height: 1px;
    background: var(--Color-Grey-Dark);
    width: 98%;
    margin: 5% 1%;
`