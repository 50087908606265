import React from 'react'

import { LabelInput, InputDescripcionProductoReadOnly, TextAreaDescripcionProducto, SinImagen, ContentImagenDeProducto } from "./Estilos";
import { ItemSeccion } from "../Titulos/Titulos";

import { SecondaryButtonEditar } from "../Buttons/Buttons";

const UnProducto = ({producto}) => {
    const {Imagenes, Descripcion } = producto

  return (
    <>
        <div className="row mt-2 mb-4">
            <div className="col-md-4">

                <LabelInput>Nombre</LabelInput>
                <InputDescripcionProductoReadOnly 
                    readOnly
                    value={producto.Nombre}
                />        
            </div>
            <div className="col-md-4">
                <LabelInput>Categoria</LabelInput>
                <InputDescripcionProductoReadOnly 
                    readOnly
                    value={producto.Grupo.Nombre}
                />

            </div>
            <div className="col-md-4">
                <LabelInput>Codigo Sicoi</LabelInput>
                <InputDescripcionProductoReadOnly 
                    readOnly
                    value={producto.CodigoSicoi}
                />

            </div>
        </div>
        <div className="row mb-4">
            <div className="col-md-6 mx-auto">
                <hr />
            </div>
        </div>
        <div className="row mt-2 mb-4">
            <div className="col-md-3">
                <LabelInput>Precio</LabelInput>
                <InputDescripcionProductoReadOnly 
                    readOnly
                    value={`USD ${producto.Precio}`}
                />        
            </div>
            <div className="col-md-3">
                <LabelInput>Stock</LabelInput>
                <InputDescripcionProductoReadOnly 
                    readOnly
                    value={producto.Stock}
                />

            </div>
            <div className="col-md-3">
                <LabelInput>Metros Estandard</LabelInput>
                <InputDescripcionProductoReadOnly 
                    readOnly
                    value={producto.MetrosEstandard === '' ? 'Sin indicar' : producto.MetrosEstandard }
                />

            </div>
            <div className="col-md-3">
                <LabelInput>Estado para la Venta</LabelInput>
                <InputDescripcionProductoReadOnly 
                    readOnly
                    value={producto.Activo === true ? 'Activo' :  'Pausado' }
                />

            </div>
        </div>
        <div className="row mb-4">
            <div className="col-md-6 mx-auto">
                <hr />
            </div>
        </div>
        <div className="row mt-2 mb-4">
            <div className="col-md-12 mx-auto p-4">
                { Imagenes.length === 0 ?
                    <>
                        <div className="row">
                            <div className="col-md-8 mx auto">
                                <SinImagen>
                                    <i className="bi bi-camera"></i>
                                    <p>Este Producto no posee ninguna imagen.</p>
                                    <div className="row">
                                        <div className="col-md-6 mx-auto">
                                            <SecondaryButtonEditar link={`/Producto/Imagenes/${producto.CodigoSicoi}`} texto='Agregar Imagen' />
                                        </div>
                                    </div>
                                    
                                </SinImagen>
                            </div>
                        </div>

                    </>
                    :
                    <>
                        <ItemSeccion title='Imagenes del producto.' />
                        <div className="row">
                        {
                            Imagenes.map(imagen => (

                                <div className="col-md-3 mx-auto p-2" key={imagen.Id}>
                                    <ContentImagenDeProducto className='mt-2 mb-2'>
                                        <img 
                                            src={`https://api.ruedadigital.com.ar/${imagen.URLImagen}`} 
                                            alt={`Imagen Producto ${producto.Nombre} - Id:${imagen.Id}`}
                                        />

                                    </ContentImagenDeProducto>
                                </div>
                            ))
                        }
                        
                        </div>
                        <div className="row">
                            <div className="col-md-4 mx-auto">
                                <SecondaryButtonEditar link={`/Producto/Imagenes/${producto.CodigoSicoi}`} texto='Editar o Agregar Imagen' />
                            </div>
                        </div>

                    </>

                }
            </div>

        </div>
        <div className="row mb-4">
            <div className="col-md-6 mx-auto">
                <hr />
            </div>
        </div>
        <div className="row mt-2 mb-4">
            <div className="col-md-12 mx-auto p-4">
                { Descripcion === null ?
                    <>
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <SinImagen>
                                    <i className="bi bi-journal-check"></i>
                                    <p>Este Producto no posee ninguna descripcion.</p>
                                    <div className="row">
                                        <div className="col-md-6 mx-auto">
                                            <SecondaryButtonEditar link={`/Producto/Descripcion/${producto.CodigoSicoi}`} texto='Agregar Descripción' />
                                        </div>
                                    </div>
                                
                                </SinImagen>

                            </div>
                        </div>

                      
                    </>
                    :
                    <>
                    <ItemSeccion title='Descripción del producto.' />
                    <div className="row mb-4 mt-4">
                        <div className="col-md-3">
                            <LabelInput>Largo</LabelInput>
                            <InputDescripcionProductoReadOnly 
                                readOnly
                                value={Descripcion.LargoRollo === '' ? 'Sin indicar' : Descripcion.LargoRollo }
                            />

                        </div>
                        <div className="col-md-3">
                            <LabelInput>Ancho</LabelInput>
                            <InputDescripcionProductoReadOnly 
                                readOnly
                                value={Descripcion.AnchoRollo === '' ? 'Sin indicar' : Descripcion.AnchoRollo}
                            />
                        </div>
                        <div className="col-md-3">
                            <LabelInput>Composicion</LabelInput>
                            <InputDescripcionProductoReadOnly 
                                readOnly
                                value={Descripcion.ComposicionRollo === '' ? 'Sin indicar' : Descripcion.ComposicionRollo}
                            />
                        </div>
                        <div className="col-md-3">
                            <LabelInput>Peso</LabelInput>
                            <InputDescripcionProductoReadOnly 
                                readOnly
                                value={Descripcion.PesoRollo === '' ? 'Sin indicar' : Descripcion.PesoRollo}
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-6 mx-auto">
                            <hr />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <LabelInput>Descripción</LabelInput>
                            <TextAreaDescripcionProducto
                                rows={5}
                                value={Descripcion.Descripcion === '' ? 'Sin indicar' :  Descripcion.Descripcion}
                                readOnly
                            >

                            </TextAreaDescripcionProducto>
                        </div>
                        <div className="col-md-6">
                            <LabelInput>Observaciones</LabelInput>
                            <TextAreaDescripcionProducto
                                rows={5}
                                value={Descripcion.Observaciones === '' ? 'Sin indicar' : Descripcion.Observaciones }
                                readOnly
                            >

                            </TextAreaDescripcionProducto>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mx-auto">
                            <SecondaryButtonEditar link={`/Producto/Descripcion/${producto.CodigoSicoi}`} texto='Editar Descripción' />
                        </div>
                    </div>
                    </>

                }
            </div>

        </div>
    </>
  )
}

export default UnProducto