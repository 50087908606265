import React, { useState } from "react";

import { editImagenCategoria } from "../../services/Categorias";

import {
  ImagenCategoria,
  ContentInputEditImagenCategoria,
  LabelInputEditImagen,
  LeyendaEditImagen,
  ContentMsgError,
  ErrorValueInput,
} from "./Estilos";

import Loading from "../Loading/Loading";

import { ButtonSumitCambios, ButtonCancelCambios } from "../Buttons/Buttons";

import FormActiveCategoria from "./FormActiveCategoria";

import ModalExito from "../Modals/ModalExito";
import ModalError from "../Modals/ModalError";


const FormEditCategoria = ({ categoria, token }) => {


  const [Imagen, setImagen] = useState(false);
  const [errorInput, setErrorInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentImg, setCurrentImg] = useState('')
  const [modalExito, setModalExito] = useState(false)
  const [modalError, setModalError] = useState(false)

  const changeImagen = (e) => {
    setLoading(true);
    const readerFile = new FileReader();
    readerFile.readAsDataURL(e[0]);
    readerFile.addEventListener("load", (result) => {
        setImagen(e)
        setCurrentImg(result.currentTarget.result)
    });
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    if(Imagen === false) {
      setErrorInput(true);
    }else{
        const result = await editImagenCategoria(categoria.Id, token, Imagen[0])
        if(result === true){
            setModalExito(true)
        }else{
            setModalError(true)
        }
    }

    setLoading(false)

    
  };

  if(loading){
      return(
        <Loading />
      )
  }

  return (
    <>

        <div className="mt-4">
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row p-2">
                    <div className="col-md-6">
                        <ContentInputEditImagenCategoria className="row">
                        <div className="col-md-4">
                            {categoria.URLImagen === "" && currentImg === ''  ? (
                            <ImagenCategoria>
                                <i className="bi bi-camera"></i>
                                <p>Sin Imagen</p>
                            </ImagenCategoria>
                            ) : (
                            <div>
                                { currentImg === '' ?
                                    <img
                                        src={`https://api.ruedadigital.com.ar/${categoria.URLImagen}`}
                                        className="w-100"
                                        alt={categoria.Nombre}
                                    />
                                    :
                                    <img
                                        src={currentImg}
                                        className='w-100'
                                        alt='Current Imagen'
                                    />

                                }

                            </div>
                            )}
                        </div>
                        <div className="col-md-8 align-self-center">
                            <LabelInputEditImagen htmlFor="imagen">
                            <i className="bi bi-arrow-up-short"></i>
                            Editar Imagen
                            </LabelInputEditImagen>

                            <input
                            type="file"
                            accept="image/*"
                            name="imagen"
                            id="imagen"
                            className="input-file-imagen"
                            onChange={(e) => changeImagen(e.target.files)}
                            />

                            <LeyendaEditImagen>
                            *La imagen a cargar debe tener una medida de 250x250 px con un
                            peso máximo de 2 mb y un formato .png
                            </LeyendaEditImagen>
                            {errorInput === true && (
                            <ContentMsgError>
                                <ErrorValueInput className="bi bi-exclamation-triangle p-2 m-4">
                                <span className="p-2">
                                    No ha seleccionado ninguna imagen.
                                </span>
                                </ErrorValueInput>
                            </ContentMsgError>
                            )}
                        </div>
                        </ContentInputEditImagenCategoria>
                    </div>
                </div>
                <hr />
                <div className="row p-2">
                <div className="col-md-6 align-self-center">
                    <div className="row">
                    <div className="col-md-6">
                        <ButtonSumitCambios texto="Guardar Cambios" type="submit" />
                    </div>
                    <div className="col-md-6">
                        <ButtonCancelCambios texto="Cancelar Cambios" type="button" />
                    </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <FormActiveCategoria categoria={categoria} />
                </div>
                </div>
            </form>
        </div>

        <ModalExito 
            data={modalExito}
            icono='bi bi-check-circle'
            titulo='Todo ha ido bien.'
            message={`Se ha cambiado la imagen de la categoria ${categoria.Nombre}`}
        />

            <ModalError
                data={modalError}
                icono='bi bi-exclamation-triangle'
                titulo='Ups!'
                message={`Ah ocurrido un error al intentar realizar la operación solicitada.`}
            />
      </>

  );
};

export default FormEditCategoria;
