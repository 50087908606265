import React from "react";


import { ContentTituloSeccion, ItemSeccionStyle, LineItemSeccion } from "./Estilos";

import { ReturnButton } from "../Buttons/Buttons";

export const TituloPanelAdm = ({title}) => {
    return (
        <ContentTituloSeccion>
            <h1>{title}</h1>
        </ContentTituloSeccion>
    )

}

export const TituloSeccion = ({title}) => {
    return (
       
        <ContentTituloSeccion className="row">
            <div className="col-md-9 align-self-center">
                <h1> <span>Panel /  </span> {title}</h1>
            </div>
            <div className="col-md-3">
                <ReturnButton />
            </div>
        </ContentTituloSeccion>

            
     
    )
}

export const ItemSeccion = ({title}) => {
    return (
        <>
            <ItemSeccionStyle>
                {title}
            </ItemSeccionStyle>

            <LineItemSeccion />
        </>

    )
}

export const TituloSubSeccion = ({title, seccion}) => {
    return (
       
        <ContentTituloSeccion className="row">
            <div className="col-md-9 align-self-center">
                <h1> <span>Panel / {seccion}  </span> {title}</h1>
            </div>
            <div className="col-md-3">
                <ReturnButton />
            </div>
        </ContentTituloSeccion>

            
     
    )
}