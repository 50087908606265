import React, {useState, Suspense} from 'react'
import { Link } from 'react-router-dom';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import ProductoSinImagen from "../../assets/Images/sinImagen.jpg";
import { ImagenProducto, LeyendaProductoSinImagen } from "./Estilos";

const CardsProductos = ({producto}) => {
    const [drop, setDrop] = useState(false)
    const {Imagenes} = producto

  return (
    <div className={producto.Id % 2 === 0 ? 'row mt-2 mb-2 text-center  p-4': 'row mt-2 mb-2 text-center bg-grey p-4' }>
                    
        <div className="col-md-2 align-self-center">
            <Suspense fallback={<h1>Cargando</h1>}> 
            {
                producto.Imagenes.length === 0 ?
                <div>
                    <ImagenProducto  src={ProductoSinImagen} alt="Producto sin imagen" />
                    <LeyendaProductoSinImagen>
                        Producto sin imagen
                    </LeyendaProductoSinImagen>
                </div>
                :
                <div>
                    <ImagenProducto  
                        src={`https://api.ruedadigital.com.ar/${Imagenes[0].URLImagen}`}
                        alt="Imagen Producto" />
                </div>
            }
            </Suspense>


        </div>
        
        <div className="col-md-2 align-self-center">
            <p className="datoColumnaUsuario">
                {producto.Nombre}
            </p>

        </div>

        <div className="col-md-2 align-self-center">
            <p className="datoColumnaUsuario">
                USD {producto.Precio}
            </p>
        </div>

        <div className="col-md-2 align-self-center">
            <p className="datoColumnaUsuario">
                {producto.Stock}
            </p>
        </div>

        <div className="col-md-2 align-self-center">
            <p className="datoColumnaUsuario">
                {producto.CodigoSicoi}
            </p>
        </div>

        <div className="col-md-1 align-self-center">

                {
                    producto.Activo === true ?
                    <p className="datoColumnaUsuario text-green">
                        Activo
                    </p>
                    :
                    <p className="datoColumnaUsuario text-alert">
                        Inactivo
                    </p>
                }

        </div>
        
        <div className="col-md-1 align-self-center">
            <ButtonDropdown isOpen={drop} onClick={()=>setDrop(!drop)} >
                <DropdownToggle  color='secondary'>
                <i className="bi bi-three-dots-vertical"></i>
                </DropdownToggle>
                <DropdownMenu className='contentLinkOpcionUsuario'>
                    <DropdownItem header className='linkOpcionUsuario' >
                        <Link to={`/Producto/Ver/${producto.CodigoSicoi}`} className='linkOpcionUsuario'>
                            Ver Producto
                        </Link>
                    </DropdownItem>
                    <DropdownItem header className='linkOpcionUsuario'>
                        <Link to={`/Producto/Descripcion/${producto.CodigoSicoi}`} className='linkOpcionUsuario'>
                            { producto.Descripcion === null ?
                                    <>
                                        Agregar descripción
                                    </>
                                     
                                    :
                                    <>
                                        Editar descripción
                                    </>
                                }
                        </Link>
                    </DropdownItem>
                    <DropdownItem header className='linkOpcionUsuario'>

                            <Link to={`/Producto/Imagenes/${producto.CodigoSicoi}`} className='linkOpcionUsuario'>
                                { producto.Imagenes.length === 0 ?
                                    <>
                                        Agregar imagenes
                                    </>
                                     
                                    :
                                    <>
                                        Editar imagenes
                                    </>
                                }
                               
                            </Link>
                            :


                    </DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
        </div>
    </div>
  )
}

export default CardsProductos