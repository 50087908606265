import React, { useState, useEffect } from 'react'

import { get_AllOrdenes } from "../services/Ordenes";

import Loading from "../components/Loading/Loading";
import Navegacion from "../components/Navegacion/Navegacion";

import { TituloSeccion, ItemSeccion } from "../components/Titulos/Titulos";
import { ContentSeccion } from "../components/Content/Estilos";

import TituloColumnas from "../components/CardsOrdenes/TituloColumnaOrdens";
import CardOrden from "../components/CardsOrdenes/CardOrden";


import { ReturnButton } from "../components/Buttons/Buttons";
import { CardSinDatos } from "../components/CardsBanners/Estilos";
import { get_Token } from '../helpers/getToken';

const Ordenes = ({ title }) => {

  const [columns, setColummns] = useState(false)
  const [loading, setLoading] = useState(true)
  const [ordenes, setOrdenes] = useState()


  const token = get_Token()

  useEffect(()=>{
    const getData= async(token) => {
      setLoading(true)
      const data= await get_AllOrdenes(token)
      if(data){
        const ordena = data.sort(function(a, b) {
          if (b.Id > a.Id) {
            return 1;
          }
          if (b.Id < a.Id) {
            return -1;
          }
          return 0;
        });
        console.log(ordena)
        setOrdenes(ordena)
      }else{
        setOrdenes([])
      }
      setLoading(false)
    }
    getData(token)
  },[])

  if(loading){
    return (
      <Loading />
    )
  }



  
  return (
    <div className="row">

      <div className={!columns ? 'col-md-2 layout-general' : 'col-md-1 layout-general'}>
        <Navegacion hideSide={setColummns} active='/Ordenes' />
      </div>

      <div className={!columns ? 'col-md-10 layout-general' : 'col-md-11 layout-general'}>
        <TituloSeccion title={title} />

        <ContentSeccion>
          <ItemSeccion title='Ordenes' />

          {
           ordenes.length === 0 ?
            <CardSinDatos>
              <p>Aún no tienes ordenes cargadas en tu plataforma. <br /></p>
              <div className="row p-4">
                <div className="col-md-4 mx-auto">
                  <ReturnButton />
                </div>
              </div>
            </CardSinDatos> 
            :
            <>
              <TituloColumnas />
              {
                ordenes.map(unaOrden => (
                  <CardOrden orden={unaOrden} key={unaOrden.Id} />
                ))
              }
            </>
          }
        </ContentSeccion>


      </div>

    </div>

  )

}

export default Ordenes