import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {  get_PerfilCliente } from "../services/Clientes";

import Loading from "../components/Loading/Loading";
import Navegacion from "../components/Navegacion/Navegacion";

import { TituloSeccion, ItemSeccion } from "../components/Titulos/Titulos";
import { ContentSeccion } from "../components/Content/Estilos";

import SinDatos from "../components/Errors/SinDatos";
import UnUsuario from "../components/CardsUsuarios/CardUnUsuario";
import { get_Token } from "../helpers/getToken";

const ClienteVerUno = ({title}) => {

    
    const {mail} = useParams()

    const token = get_Token()
    
    const [columns, setColummns] = useState(false);
    const [loading, setLoading] = useState(true)
    const [usuario, setUsuario] = useState(null);

    useEffect(()=>{
        const getData = async(token, mail) =>{
            setLoading(true)
            const data = await get_PerfilCliente(token, mail)

            if(data){
                setUsuario(data)
            }else{
                setUsuario(null)
            }

            setLoading(false)
        }

        getData(token, mail)
    }, [mail])

    if(loading){
        return (
            <Loading />
        )
    }

    return (
        <div className="row">
            <div className={!columns ? "col-md-2 layout-general" : "col-md-1 layout-general"}>
                <Navegacion hideSide={setColummns} active="/Clientes" />
            </div>

            <div className={ !columns ? "col-md-10 layout-general" : "col-md-11 layout-general"}>
                <TituloSeccion title={title} />

                <ContentSeccion>
                    { usuario === null ?
                        <>
                            <SinDatos title='Ups ...' message={`No encontramos datos asociados al cliente Mail Ref: ${mail}`} />
                        </>
                        :
                        <>
                            <ItemSeccion title={`Datos de Cliente: ${usuario.Cliente.Nombre} ${usuario.Cliente.Apellido}`} />
                            <div className="mt-4 mb-4">
                                <UnUsuario usuario={usuario} />
                            </div>
                        </>

                    }


                </ContentSeccion>
            </div>
        </div>
    )
}

export default ClienteVerUno