import React, {useState} from 'react'


import { 
    active_BannerTexto, 
    edit_BannerTexto, 
    delete_BannerTexto 
} from "../../services/BannersTexto";

import { 
  LabelInput, 
  TextArea,
  DivTextEnd,
  SmallContadorAlert,
  SmallContadorAproved,
  LabelColorRojo,
  LabelColorNegro,
  LabelColorGris,
  ShowBannerTexto,
  ContentMsgError,
  ErrorValueInput
} from "./Estilos";

import Loading from "../Loading/Loading";
import { LineSeparadora } from "../Content/Estilos";
import { ButtonSumitCambios, ButtonCancelCambios } from "../Buttons/Buttons";

import { EstadoActivo, EstadoInactivo } from "./Estilos";
import { ActivaElemento, InactivaElemento, DeleteElemento } from "../Buttons/Buttons";

import ModalExito from "../Modals/ModalExito";
import ModalError from "../Modals/ModalError";

const FormEditBanner = ({banner, token}) => {

    const [loading, setLoading] = useState(false)
    const [color, setColor] = useState(banner.BackgroundColor)
    const [texto, setTexto] = useState(banner.Texto)
    const [CaraceteresRestantes, setCaracteresRestantes] = useState(`${150 - texto.length}`)

    const [errorTexto, setErrorTexto] = useState(null)
    const [errorColor, setErrorColor] = useState(null)

    const [msg, setMsg] = useState('')

    const [modalExito, setModalExito] = useState(false)
    const [modalError, setModalError] = useState(false)


    const checkTexto = (e) => {
    setTexto(e.target.value)
    setCaracteresRestantes(`${150 - texto.length}`) 
    if(texto.length > 1){
      setErrorTexto(null)
    }

    }

    const checkColor = (e) => {
    setColor(e)
    setErrorColor(null)
    }

    const active = async (token) => {
        setLoading(true)
        const data = await active_BannerTexto(banner.Id, token)
        if(data){
            if(banner.Active === true){
                setMsg(`Se ha desactivado el banner de texto Id Ref: ${banner.Id} de forma correcta`)
            }else{
                setMsg(`Se ha Activado el banner de texto Id Ref: ${banner.Id} de forma correcta`)
            }
            setModalExito(true)
            
        }else{
            setMsg('Ah ocurrido un error al intentar procesar tu solicitud')
            setModalError(true)

        }
        setLoading(false)
    }

    const deleteElement = async(token) => {
        setLoading(true)
        const data = await delete_BannerTexto(banner.Id)
        if(data){
            setMsg(`Hemos eliminado el Banner de texto Id Ref:  ${banner.Id} de forma correcta`)
            setModalExito(true)
        }else{
            setMsg('Ah ocurrido un error al intentar procesar tu solicitud')
            setModalError(true)
        }

        setLoading(false)
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        if(texto === ''){
        setErrorTexto('Este campo es obligatorio.')
        return
        } else if(color === ''){
        setErrorColor('Debes seleccionar un color.')
        return
        } else{
    
        setLoading(true)

        const body = {
            Texto: texto, 
            BackgroundColor: color
        }
        const data = await edit_BannerTexto(banner.Id, token, body)

        if(data){
            setMsg('Hemos editado el Banner de texto de forma correcta')
            setModalExito(true)
        }else{
            setMsg('Ah ocurrido un error al intentar procesar tu solicitud')
            setModalError(true)
        }

        setLoading(false)
        }

    }

    if(loading){
        return (
        <Loading />
        )
    }

    return (
        <>
            <div className="row mt-4 mb-4">
                <div className="col-md-11">
                    <LabelInput>Asi se ve tu banner.</LabelInput>
                    <ShowBannerTexto style={{backgroundColor: `${banner.BackgroundColor}`}}>
                        <p>{banner.Texto}</p>
                    </ShowBannerTexto>

                    <LineSeparadora />

                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="div mb-2 mb-2">
                            <LabelInput htmlFor='texto'>Editar texto</LabelInput>
                            {errorTexto != null && (
                                <ContentMsgError>
                                    <ErrorValueInput className="bi bi-exclamation-triangle p-2 m-4">
                                        <span className="p-2">
                                            {errorTexto}
                                        </span>
                                    </ErrorValueInput>
                                </ContentMsgError>
                            )}

                            <TextArea
                                placeholder='Ingresa el texto del banner, longitud maxima 150 caracteres.'
                                id='texto'
                                value={texto}
                                onChange={(e)=>checkTexto(e)}
                                maxLength={150}
                            />
                            { CaraceteresRestantes > 50 ?
                                <DivTextEnd>
                                    <SmallContadorAproved>
                                        Carateres Restantes {CaraceteresRestantes}
                                    </SmallContadorAproved>
                                </DivTextEnd>

                            :
                                <DivTextEnd>
                                    <SmallContadorAlert>
                                        Carateres Restantes {CaraceteresRestantes}
                                    </SmallContadorAlert>
                                </DivTextEnd>
                            }
                        </div>
                        
                        <div className="div mb-2 mb-2">
                            <LabelInput> Editar Color de Fondo </LabelInput>
                            {errorColor != null && (
                                <ContentMsgError>
                                    <ErrorValueInput className="bi bi-exclamation-triangle p-2 m-4">
                                        <span className="p-2">
                                            {errorColor}
                                        </span>
                                    </ErrorValueInput>
                                </ContentMsgError>
                            )}

                            <div  className='row mt-2 mb-2'>
                                <div className="col-md-4">
                                    <input type="radio" value='#E42B31' id='Rojo' name='color' onChange={(e)=>checkColor(e.target.value)} />
                                    <LabelColorRojo htmlFor='Rojo'  />
                                </div>

                                <div className="col-md-4">
                                    <input type="radio" value='#000000' id='Negro' name='color' onChange={(e)=>checkColor(e.target.value)} />
                                    <LabelColorNegro htmlFor='Negro' />
                                </div>

                                <div className="col-md-4">
                                    <input type="radio" value='#7F7F7F' id='Gris' name='color' onChange={(e)=>checkColor(e.target.value)} />
                                    <LabelColorGris htmlFor='Gris' />
                                </div>

                            </div>
                    
                        </div>
                        <hr />
                        <LabelInput>Asi se vera tu banner Editado.</LabelInput>
                        <ShowBannerTexto style={{backgroundColor: `${color}`}}>
                            <p>{texto}</p>
                        </ShowBannerTexto>

                        <LineSeparadora />


                        <div className="row">

                            <div className="col-md-3 p-3">
                                <ButtonSumitCambios texto="Editar Banner" type="submit"  />
                            </div>
                            <div className="col-md-2 p-3">
                                <ButtonCancelCambios texto="Cancelar" type="button" />
                            </div>
                            <div className="col-md-7 border-left p-3 bg-grey" >
                                <div className="row">
                                    <div className="col-md-7 align-self-center">
                                        { banner.Active === true ? 
                                            <div className='row '>
                                                <div className="col-md-5 align-self-center">
                                                    <EstadoActivo>
                                                        <span>Estado: </span> Activa
                                                    </EstadoActivo>
                                                </div>
                                                <div className="col-md-7">
                                                    <InactivaElemento 
                                                        type="button"
                                                        icono='bi bi-pause-btn'
                                                        texto='Pausar'
                                                        funcion={active}
                                                        parametro={token}
                                                    />

                                                </div>

                                            </div>

                                            :
                                            <div className="row">
                                                <div className="col-md-5 align-self-center">
                                                    <EstadoInactivo>
                                                        <span>Estado: </span> Pausada
                                                    </EstadoInactivo>
                                                </div>
                                                <div className="col-md-7">
                                                    <ActivaElemento
                                                        icono='bi bi-play'
                                                        texto='Activar'
                                                        funcion={active}
                                                        parametro={token}

                                                    />
                                                </div>
                                            </div>

                                        }
                                    </div>
                                    <div className="col-md-5">
                                        <DeleteElemento 
                                            texto="Eliminar"
                                            icono="bi bi-trash"
                                            funcion={deleteElement}
                                            parametro={token}
                                        />
                                    </div>

                                </div>
                            </div>
    
    
                        </div>


                    </form>
                </div>
            </div>

            <ModalExito 
                data={modalExito}
                icono='bi bi-check-circle'
                titulo='Todo ha ido bien.'
                message={`${msg}`}
            />

            <ModalError
                data={modalError}
                icono='bi bi-exclamation-triangle'
                titulo='Ups!'
                message={`${msg}`}
            />
        </>
    )
}

export default FormEditBanner

