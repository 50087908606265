import React, {useState} from 'react'

import { add_ImagenProducto, delete_ImagenProducto } from "../../services/Productos";

import { 
  LabelInput,  
  InputDescripcionProductoReadOnly, 
  SinImagen, 
  LabelInputAddImagen,
  LeyendaAddImagen,
  ContentMsgError,
  ErrorValueInput,
  ContentImagenDeProducto,
  ButtonEliminaImagen,
  ButtonVerImagen
} from "./Estilos";

import { ItemSeccion } from "../Titulos/Titulos";
import { ButtonSumitCambios, ButtonCancelCambios } from "../Buttons/Buttons";

import Loading from "../Loading/Loading";
import ModalExito from "../Modals/ModalExito";
import ModalError from "../Modals/ModalError";


const FormImagensProductos = ({producto, token}) => {

  const {Imagenes} = producto

  const [Imagen, setImagen] = useState(false);
  const [errorInput, setErrorInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentImg, setCurrentImg] = useState('')

  const [modalExito, setModalExito] = useState(false)
  const [modalError, setModalError] = useState(false)
  const [msg, setMsg] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if(Imagen === false) {
      setErrorInput(true);
    }else{
        const result = await add_ImagenProducto(producto.CodigoSicoi, token, Imagen[0])
        if(result === true){
            setMsg(`Se agregado una imagen al producto ${producto.Nombre}`)
            setModalExito(true)
        }else{
            setModalError(true)
        }
    }
    setLoading(false);

    
  };


  const changeImagen = (e) => {
    setLoading(true);
    const readerFile = new FileReader();
    readerFile.readAsDataURL(e[0]);
    readerFile.addEventListener("load", (result) => {
        setImagen(e)
        setCurrentImg(result.currentTarget.result)
    });
    setLoading(false);
  };


  const verImagen = (e) => {
    window.open(`https://api.ruedadigital.com.ar/${e.URLImagen}`, '_blank');
  }

  const deleteImagen =  async (e) => {

    const consulta = window.confirm(`Estas a punto de eliminar una imagen del Producto ${producto.Nombre}`)
    setLoading(true)
    if(consulta === true){
      const result = await delete_ImagenProducto(e.Id, token)
      if(result){
        setMsg(`Se eliminado una imagen al producto ${producto.Nombre}`)
        setModalExito(true)
      }else{
        setModalError(true)
      }

    }

    setLoading(false);
  }

  if(loading){
    return(
      <Loading />
    )
  }

  return (
    <>
      <div className="row mt-2 mb-4">
        <div className="col-md-4">
          <LabelInput>Nombre</LabelInput>
          <InputDescripcionProductoReadOnly 
            readOnly
            value={producto.Nombre}
          />
        </div>

        <div className="col-md-4">
          <LabelInput>Categoria</LabelInput>
          <InputDescripcionProductoReadOnly 
            readOnly
            value={producto.Grupo.Nombre}
          />
        </div>

        <div className="col-md-4">
          <LabelInput>Codigo Sicoi</LabelInput>
          <InputDescripcionProductoReadOnly 
            readOnly
            value={producto.CodigoSicoi}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-6 mx-auto">
          <hr />
        </div>
      </div>
    { Imagenes.length === 0 ?
      <>
        <div className="row">
          <div className="col-md-6 mx-auto">
            <SinImagen>
            <i className="bi bi-camera"></i>
            <p>Este Producto no posee ninguna imagen.</p>
            </SinImagen>
          </div>
        </div>
      </>
      :
      <>
        <ItemSeccion title='Imagenes del producto.' />
        <div className="row mb-4 mt-4">
          {
            Imagenes.map(imagen => (

              <div className="col-md-3 p-2" key={imagen.Id}>
                <ContentImagenDeProducto className='mt-2 mb-2'>
                  <img 
                    src={`https://api.ruedadigital.com.ar/${imagen.URLImagen}`} 
                    alt={`Imagen Producto ${producto.Nombre} - Id:${imagen.Id}`}
                  />
                  <hr />
                  <div className="row mt-1 mb-1">
                    <div className="col-md-6">
                      <ButtonEliminaImagen
                        onClick={()=>deleteImagen(imagen)}
                      > 
                        <i className="bi bi-trash"></i> Eliminar
                      </ButtonEliminaImagen>
                    </div>
                    <div className="col-md-6">
                      <ButtonVerImagen
                        onClick={()=>verImagen(imagen)}
                      >
                        <i className="bi bi-eye"></i> Ver
                      </ButtonVerImagen>
                    </div>
                  </div>
                </ContentImagenDeProducto>
  
              </div>
            ))
          }
        </div>
      </>
    }
    <div className="row mb-4">
      <div className="col-md-6 mx-auto">
          <hr />
      </div>
    </div>
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="row mb-4">
        <div className="col-md-2"></div>
        <div className="col-md-4 align-self-center">
          <LabelInputAddImagen htmlFor='imagen'>Seleccionar Imagen</LabelInputAddImagen>
          <input
            type="file"
            accept="image/*"
            name="imagen"
            id="imagen"
            className="input-file-imagen"
            onChange={(e) => changeImagen(e.target.files)}
          />
          <LeyendaAddImagen>
          *La imagen a cargar debe tener una medida de 250x250 px con un peso máximo de 2 mb y un formato .png
          </LeyendaAddImagen>
          {errorInput === true && (
            <ContentMsgError>
                <ErrorValueInput className="bi bi-exclamation-triangle p-2 m-4">
                  <span className="p-2">
                    No ha seleccionado ninguna imagen.
                  </span>
                </ErrorValueInput>
            </ContentMsgError>
          )}
        </div>
        <div className="col-md-4">
          { currentImg === '' ?
            <SinImagen>
              <i className="bi bi-camera"></i>
              <p>No haz seleccionado ninguna imagen.</p>
            </SinImagen>
            :
            <img
              src={currentImg}
              className='w-100'
              alt='Current Imagen'
            />

          }
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-4">
          <ButtonSumitCambios texto="Agregar imagen" type="submit" />
        </div>
        <div className="col-md-4">
          <ButtonCancelCambios texto="Cancelar Cambios" type="button" />
        </div>
      </div>

    
    </form>

    <ModalExito 
      data={modalExito}
      icono='bi bi-check-circle'
      titulo='Todo ha ido bien.'
      message={msg}
    />

    <ModalError
      data={modalError}
      icono='bi bi-exclamation-triangle'
      titulo='Ups!'
      message={`Ah ocurrido un error al intentar realizar la operación solicitada.`}
    />
    
    
    </>
  )
}

export default FormImagensProductos