import React from 'react'

const TitulosColumnaUsuarios = () => {
  return (
    <div className="row text-center tablaUsuario p-4">
                    
    <div className="col-md-2">
      <p className="tituloColumnaUsuario">
        Nombre del Cliente
      </p>
    </div>
    
    <div className="col-md-2">
      <p className="tituloColumnaUsuario">
        Razón Social
      </p>

    </div>
    <div className="col-md-2">
        <p className="tituloColumnaUsuario">
            CUIT
        </p>
    </div>
    <div className="col-md-2">
        <p className="tituloColumnaUsuario">
            Tipo de cliente
        </p>
    </div>
    <div className="col-md-2">
        <p className="tituloColumnaUsuario">
            N° Sicoi
        </p>
    </div>
    <div className="col-md-1">
        <p className="tituloColumnaUsuario">
            Estado
        </p>
    </div>
    <div className="col-md-1">
        <p className="tituloColumnaUsuario">
            Más
        </p>
    </div>
    </div>
  )
}

export default TitulosColumnaUsuarios