import axios from "axios";
import { EndPointApi, Credencials } from "./config";

// Obtiene todos los Banners de Imagen
export const getAll_BannersImagen = async () => {
    try {
        const {data} = await axios({
            method:'GET',
            url: `${EndPointApi}/BannersImagenes/getAll`,
            auth: Credencials
        })


        return data.data
        
    } catch (error) {
        return null
    }
}

// Agrega un banner
export const add_BannerImagen = async(token, imagen)=>{
    try {
        const body = new FormData()
        body.append('imagen', imagen)
        const {data} = await axios({
            method:'POST',
            url:`${EndPointApi}/BannersImagenes/addOne`,
            auth: Credencials,
            data:body,
            headers:{
                "Auth-Token": token,
            }

        })

        if(data.status === 'OK'){
            return true
        }

        return null
        
    } catch (error) {
        return null
    }
}

// Obtiene un banner
export const get_BannerImagen = async(id) =>{
    try {
        const {data} = await axios({
            method:'GET',
            url:`${EndPointApi}/BannersImagenes/getOne/${id}`,
            auth: Credencials
        })


        if(!data.data.status){
            return data.data
        }

        return null
        
    } catch (error) {
        return null
    }
}

// Activa - DesActiva un Banner
export const active_BannerImagen = async(id, token) => {
    try {
        const {data} = await axios({
            method:'PUT',
            url:`${EndPointApi}/BannersImagenes/activeOne/${id}`,
            auth: Credencials,
            headers:{
                "Auth-Token": token,
            }
        })

        if(data.status === 'OK'){
            return true
        }

        return null
        
    } catch (error) {
        return null
    }
}

// Edita un  Banner
export const edit_BannerImagen = async(id, token, imagen) => {
    try {
        const body = new FormData()
        body.append('imagen', imagen)
        const {data} = await axios({
            method:'PUT',
            url:`${EndPointApi}/BannersImagenes/editOne/${id}`,
            auth: Credencials,
            data:body,
            headers:{
                "Auth-Token": token,
            }

        })

        if(data.status === 'OK'){
            return true
        }

        return null
        
    } catch (error) {
        return null
    }
}


// Elimina un Banner
export const delete_BannerImagen = async(id, token) => {
    try {
        const {data} = await axios({
            method:'DELETE',
            url:`${EndPointApi}/BannersImagenes/deleteOne/${id}`,
            auth: Credencials,
            headers:{
                "Auth-Token": token,
            }
        })

        if(data.status === 'OK'){
            return true
        }

        return null
        
    } catch (error) {
        return null
    }
}
