import React, { useState, useEffect } from "react";

import { getAll_Clientes, get_PerfilCliente } from "../services/Clientes";


import Loading from "../components/Loading/Loading";
import Navegacion from "../components/Navegacion/Navegacion";

import { TituloSeccion, ItemSeccion } from "../components/Titulos/Titulos";
import { ContentSeccion } from "../components/Content/Estilos";

import { ReturnButton } from "../components/Buttons/Buttons";
import { CardSinDatos } from "../components/CardsBanners/Estilos";

import TitulosColumnas from "../components/CardsUsuarios/TitulosColumnaUsuarios";
import CardsUsuarios from "../components/CardsUsuarios/CardsUsuarios";
import { LabelInput, InputSearchUsuario, SelectUsuario } from "../components/CardsUsuarios/Estilos";
import { get_Token } from "../helpers/getToken";



const Clientes = ({ title }) => {
  const token = get_Token()

  const [columns, setColummns] = useState(false);
  const [loading, setLoading] = useState(true)
  const [usuarios, setUsuarios] = useState([]);
  const [filterUsuario, setFilterUsuario] = useState([])

  const [searchNombre, setSearchNombre] = useState('')
  const [searchSicoi, setSearchSicoi] = useState('')
  const [searchEstado, setSearchEstado] = useState('')


  const filtraUsuarios = () => {

    setLoading(true)
    let busquedaXNombre = [] 
    let busquedaSicoi = [] 
    let busquedaEstado = []

    const data =[]


    if(searchNombre === '' && searchSicoi === '' && searchEstado === ''){
      setFilterUsuario(usuarios)
      setLoading(false)
      return
    }


    if(searchNombre !== ''){
      busquedaXNombre  = usuarios.filter(usuario => usuario.Cliente.Nombre.includes(searchNombre) || usuario.Cliente.Apellido.includes(searchNombre))
      for (const unUsuario of busquedaXNombre) {
        data.push(unUsuario)
      }
    }

    if(searchSicoi !== ''){
      const filtro = usuarios.filter(usuario => usuario.Perfil)
      busquedaSicoi  = filtro.filter(usuario => usuario.Perfil.NSicoi.includes(searchSicoi))
      console.log(busquedaSicoi)

      for (const unUsuario of busquedaSicoi) {
        data.push(unUsuario)
      }
    }

    if(searchEstado !== ''){
      let estado = (searchEstado === 'true') ? true : false
      const filtro = usuarios.filter(usuario => usuario.Perfil)
      busquedaEstado  = filtro.filter(usuario => usuario.Perfil.ValidPerfil === estado)

      for (const unUsuario of busquedaEstado) {
        data.push(unUsuario)
      }
    }



      const dataArr = new Set(data);
      const result = [...dataArr];
      setFilterUsuario(result)


    setLoading(false)

  }

  


  useEffect(()=>{
    const getData = async(token) => {
      const usuarios = await getAll_Clientes(token)
      if(usuarios){
        let registerUser = []
        for (const unUsuario of usuarios) {
            const perfilUser = await get_PerfilCliente(token, unUsuario.HashCliente)

            registerUser.push(perfilUser)
        }
        setUsuarios(registerUser)
        setFilterUsuario(registerUser)
      }else{
        setUsuarios([])
      }

      setLoading(false)
    }
    getData(token)
  },[])

  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <div className="row">
      <div className={!columns ? "col-md-2 layout-general" : "col-md-1 layout-general"}>
        <Navegacion hideSide={setColummns} active="/Clientes" />
      </div>

      <div className={ !columns ? "col-md-10 layout-general" : "col-md-11 layout-general"}>
        <TituloSeccion title={title} />

        <ContentSeccion>

        <>

             <div>
              { usuarios.length === 0 ?
              <div className="mt-4 mb-4 ">
                <ItemSeccion title='Clientes' />
                <CardSinDatos>
                  <p>Aún no hay clientes registrados en la plataforma.</p>
                  <div className="row p-4">
                    <div className="col-md-4 mx-auto">
                      <ReturnButton />
                    </div>
                  </div>
                </CardSinDatos>
              </div>

                :
                <>
                  <div className="row mb-4 busqueda-Usuarios">
                    <div className="col-md-3">
                      <LabelInput htmlFor="nombre">Filtrar por Nombre</LabelInput>
                      <InputSearchUsuario
                        onChange={(e)=>setSearchNombre(e.target.value)}
                        id='nombre'
                        placeholder="&#128269; Filtrar por Nombre"
                        value={searchNombre}
                      />

                    </div>
                    <div className="col-md-3">
                      <LabelInput htmlFor="sicoi">Filtrar por N° Sicoi</LabelInput>
                      
                      <InputSearchUsuario
                        onChange={(e)=>setSearchSicoi(e.target.value)}
                        id='sicoi'
                        placeholder="&#128269; Filtrar por N° Sicoi"
                        value={searchSicoi}
                      />
                    </div>
                    <div className="col-md-3">
                      <LabelInput htmlFor="estado">Filtrar por estado</LabelInput>
                      <SelectUsuario
                        onChange={(e)=>setSearchEstado(e.target.value)}
                        id='estado'
                      >
                        <option value="" hidden className="placeholder-select">
                          Sin definir
                        </option>
                        <option value="">
                          Todos
                        </option>
                        <option value="true">
                          Activos
                        </option>
                        <option value="false">
                          Sin Activar
                        </option>
                      </SelectUsuario>
                    </div>
                    <div className="col-md-3 align-self-end">
                      <button
                        type="button"
                        onClick={()=>filtraUsuarios()}
                        className='boton-filtrar'
                      >
                        Filtrar
                      </button>
                    </div>
                  </div>
                  <ItemSeccion title='Clientes' />

                  { filterUsuario.length > 0 ?
                    <>
                      <TitulosColumnas />
                      {
                        filterUsuario.map(unUsuario =>(
                          <CardsUsuarios usuario={unUsuario} key={unUsuario.Id} />
                        ))
                      }
                    </>
                    :
                    <>
                      <CardSinDatos>
                        <p>No hay resultados para tu busqueda.</p>
                        <div className="row p-4">
                          <div className="col-md-4 mx-auto">
                            <ReturnButton />
                          </div>
                        </div>
                      </CardSinDatos>
                    </>

                  }
                  


                </>
              }
             </div>

            </>
        </ContentSeccion>
      </div>
    </div>
  );
};

export default Clientes;
