import React, {useState, useEffect} from 'react'

import { getAll_BannersTexto } from "../services/BannersTexto";

import Navegacion from "../components/Navegacion/Navegacion";
import Loading from "../components/Loading/Loading";

import { TituloSeccion, ItemSeccion } from "../components/Titulos/Titulos";
import { ContentSeccion, LineSeparadora } from "../components/Content/Estilos";
import { CardSinDatos } from "../components/CardsBanners/Estilos";
import { CardSinBanners, CardBannersTexto } from "../components/CardsBanners/CardsBanners";

import { SecondaryButtonEditar } from "../components/Buttons/Buttons";
import { get_Token } from '../helpers/getToken';


const InitData = async () => {
  const data = await getAll_BannersTexto()

  if(data){
    return data
  }
  return null
}


const BannersTexto = ({title}) => {
    const [columns, setColummns] = useState(false)
    const [loading, setLoading] = useState(true)
    const [activos, setActivos] = useState([])
    const [inactivos, setInactivos] = useState([])
    const [existBanner, setExistBanner] = useState(true)


    useEffect(()=> {
      const getData = async()=> {
        get_Token()
        const data = await InitData()

        if(data.length === 0){
          setExistBanner(false)
        }

        if(data){
          setActivos(data.filter(unBanner => unBanner.Active === true))
          setInactivos(data.filter(unBanner => unBanner.Active === false)) 
        }else{
          setExistBanner(false)
        }
        setLoading(false)
      }
      getData()
    },[])

    if(loading){
      return (
        <Loading />
      )
    }

    return (
      <div className="row">
  
        <div className={!columns ? 'col-md-2 layout-general' : 'col-md-1 layout-general'}>
          <Navegacion hideSide={setColummns} active='/Banners_texto' />
        </div>
  
        <div className={!columns ? 'col-md-10 layout-general' : 'col-md-11 layout-general'}>
          <TituloSeccion title={title} />
          
          <ContentSeccion>
            

            { existBanner === false ?
              <>
                <CardSinBanners 
                  texto='Aún no posees nigún banner de tipo texto. Carga uno.'
                  link='/Banners_texto/Agregar'
                  textoBtn='Agregar Banner'
                />
              </>
              :
              <>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <ItemSeccion title='Banners Activos' />
                  </div>
                  <div className="col-md-3">
                    <SecondaryButtonEditar 
                      texto='Agregar Banner'
                      link='/Banners_texto/Agregar'
                    />
                  </div>
                </div>

                {
                  activos.length === 0 ?
                  <>
                    <CardSinBanners 
                      texto='No posees banners de textos activos. Carga uno.'
                      link='/Banners_texto/Agregar'
                      textoBtn='Agregar Banner'
                    />
                  </>
                  :
                  <>
                    <div className="row">
                      {
                        activos.map(bannerFilter =>(
                          <CardBannersTexto key={bannerFilter.Id} unBanner={bannerFilter} />
                        ))
                      }
                    </div>
                  </>
                }
                  <LineSeparadora />

                  <ItemSeccion title='Banners Inactivos' />

                  
                { inactivos.length === 0 ?
                  <CardSinDatos>
                    <p>No posees Banner de Textos Inactivos.</p>
                  </CardSinDatos>
                  :
                  <>
                    <div className="row">
                      {
                        inactivos.map(bannerFilter =>(
                          <CardBannersTexto key={bannerFilter.Id} unBanner={bannerFilter} />
                        ))
                      }
                    </div>
                  </>
                }
              
              </>

            }
            


            

            
          </ContentSeccion>


        </div>
  
      </div>
     
    )

}

export default BannersTexto