import React, {useState, useEffect} from 'react'
import { useParams } from "react-router-dom";

import { getOne_Categoria } from "../services/Categorias";

import { get_Token } from "../helpers/getToken";

import Loading from "../components/Loading/Loading";
import Navegacion from "../components/Navegacion/Navegacion";

import { TituloSubSeccion } from "../components/Titulos/Titulos";
import { ContentSeccion} from "../components/Content/Estilos";

import SinDatos from "../components/Errors/SinDatos";
import UnaCategoria from "../components/CardsCategorias/UnaCategoria";

const CategoriasEditar = ({title}) => {

    const {id} = useParams()
    const [columns, setColummns] = useState(false)
    const [loading, setLoading] = useState(true)
    const [categoria, setCategoria] = useState(null)

    const token = get_Token()

    useEffect(()=> {
        
        const getData = async (id) => {

            const categoria = await getOne_Categoria(id)
            setCategoria(categoria)
            setLoading(false)
        }
        getData(id)
    },[id])

    if(loading){
        return (
            <Loading />
        )
    }


    return (
        <div className="row">

            <div className={!columns ? 'col-md-2 layout-general' : 'col-md-1 layout-general'}>
                <Navegacion hideSide={setColummns} active='/Categorias' />
            </div>

            <div className={!columns ? 'col-md-10 layout-general' : 'col-md-11 layout-general'}> 

                <TituloSubSeccion title={title} seccion='Categoria'  />


                <ContentSeccion>
                    { categoria === null ?
                        <SinDatos title='Ups ...' message={`No encontramos datos asociados a la categoria Id Ref: ${id}`} />
                    :
                        <UnaCategoria categoria={categoria} token={token} />
                    }

                </ContentSeccion>
            
            </div>

        </div>

    )
}

export default CategoriasEditar