import React, { Suspense, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import LogoRueda from "./assets/Logos/logo.svg";


import LoginForm from "./pages/Login";

import Inicio from "./pages/Inicio";

import Categorias from "./pages/Categorias";
import CategoriasEditar from "./pages/CategoriasEditar";

import Productos from "./pages/Productos";
import ProductosVerUno from "./pages/ProductoVerUno";
import ProductosDescricion from "./pages/ProductoVerDescripcion";
import ProductosImagenes from "./pages/ProductoVerImagenes";

import Clientes from "./pages/Clientes";
import ClienteVerUno from "./pages/ClienteVerUno";

import Ordenes from "./pages/Ordenes";
import OrdenVerUna from "./pages/OrdenVerUna";

import BannerImagen from "./pages/BannerImagen";
import BannerImagenAgregar from "./pages/BannerImagenAgregar";
import BannerImagenEditar from "./pages/BannerImagenEditar";

import BannerTexto from "./pages/BannerTexto";
import BannerTextoAgregar from "./pages/BannerTextoAgregar";
import BannerTextoEditar from "./pages/BannerTextoEditar";

import ErrorNotFound from "./pages/ErrorNotFound";
import ErrorView from "./components/Errors/ErrorView";




import './App.css';


function App() {

  const [view, setView] = useState(true)

  useEffect(()=>{
    const data = ()=> {
      const winWidth = window.innerWidth

      if(winWidth < 480){
        setView(false)
      }
    }

    data()
  },[])




  return (
    <> 
      { view ?
            <Suspense fallback={<h1>Cargando</h1>}>
            <Router>
      
              <Routes>
      
                <Route path='/' element={<LoginForm logo={LogoRueda} />} />
      
      
                <Route path='/Inicio' element={<Inicio title='Panel del Administrador' />} />
      
                <Route path='/Categorias' element={<Categorias title='Categorias' />} />
                <Route path='/Categoria/Editar/:id' element={<CategoriasEditar title='Editar categoria' />} />
      
                <Route path='/Productos' element={<Productos title='Productos' />} />
                <Route path='/Producto/Ver/:sicoi' element={<ProductosVerUno title='Ver Producto' />} />
                <Route path='/Producto/Descripcion/:sicoi' element={<ProductosDescricion title='Descripción Producto' />} />
                <Route path='/Producto/Imagenes/:sicoi' element={<ProductosImagenes title='Imagenes Producto' />} />
      
                <Route path='/Clientes' element={<Clientes title='Clientes' />} />
                <Route path='/Clientes/:mail' element={<ClienteVerUno title='Ver Cliente' />} />
      
                <Route path='/Ordenes' element={<Ordenes title='Ordenes' />} />
      
                <Route path='/Orden/:hash' element={<OrdenVerUna title='Ver Orden' />} />
      
                <Route path='/Banners_imagen' element={<BannerImagen title='Banners de Imagen' />} />
                <Route path='/Banners_imagen/Agregar' element={<BannerImagenAgregar title='Agregar Banner de Imagen' />} />
                <Route path='/Banners_imagen/Editar/:id' element={<BannerImagenEditar title='Editar Banner de Imagen' />} />
      
                <Route path='/Banners_texto' element={<BannerTexto title='Banners de Texto' />} />
                <Route path='/Banners_texto/Agregar' element={<BannerTextoAgregar title='Agregar Banner de Texto' />} />
                <Route path='/Banners_texto/Editar/:id' element={<BannerTextoEditar title='Editar un Banner de Texto' />} />
      
                <Route path={'*'} element={<ErrorNotFound logo={LogoRueda} />} />
      
      
      
              </Routes>
            </Router>
      
            </Suspense>
          :
          <ErrorView logo={LogoRueda} />

      }

    </>






  );
}

export default App;
