import axios from "axios";
import { EndPointApi, Credencials } from "./config";

// Obtiene todas las categorias
export const getAll_Categorias = async () => {
    try {
        const categorias = await axios({
            method:'GET',
            url: `${EndPointApi}/categorias/getAll`,
            auth: Credencials
            
        })

        return {
            data: categorias.data
        }
    } catch (error) {
        return null
    }
}

// Obtiene los datos de una Categoria
export const getOne_Categoria = async(id) => {
    try {
        const {data} = await axios({
            method: 'GET',
            url: `${EndPointApi}/categorias/getById/${id}`,
            auth: Credencials
        })

        if(data.status === 'OK'){
            return data.data
        }

        return null
        
    } catch (error) {
        return null
    }
}

// Activa / DesActiva una Categoria
export const activeCategoria = async(id, token) => {
    try {
        const {data} = await axios({
            method:'PUT',
            url: `${EndPointApi}/categoria/activeOne/${id}`,
            auth: Credencials,
            headers:{
                "Auth-Token": token
            }
        })



        if(data.status === 'OK'){
            return true
        }

        return null
        
    } catch (error) {
        return null
    }
}


// Cambia la Imagen de una categoria
export const editImagenCategoria = async(id, token, imagen) => {

    try {
        const body = new FormData()
        body.append('imagen', imagen)
        const {data} = await axios({
            method:'PUT',
            url: `${EndPointApi}/categoria/addImagen/${id}`,
            auth: Credencials,
            data:body,
            headers:{
                "Auth-Token": token,
            }
        })

        if(data.status === 'OK'){
            return true
        }

        return null
        
    } catch (error) {
        return null
    }
}