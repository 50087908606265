import React, {useEffect} from 'react'

import NotFound from "../components/Errors/NotFound";
import { get_Token } from '../helpers/getToken';

const ErrorNotFound = ({logo}) => {

  useEffect(()=>{
    get_Token()
  })

  return (
    <NotFound 
        logo={logo} 
        title='Pagina no encontrada' 
        message='Hubo un error en lo que estás buscando, te recomendamos intentarlo de nuevo.'
        link='/Inicio'
        texto='Ir al Inicio'
    />
  )
}

export default ErrorNotFound