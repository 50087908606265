import React from 'react'

import LoginForm from "../components/LoginForm/LoginForm";

const Login = ({logo}) => {
  return (
    <LoginForm logo={logo} />
  )
}

export default Login