import React  from 'react';
import { Modal } from 'reactstrap';

import { 
  ContentModal,
  ContentMensajeExito
} from "./Estilos";

import { RedPrimaryButton } from "../Buttons/Buttons";

const ModalLogOut = ({titulo, message, icono, data}) => {
  
  return (
    <ContentModal>
      <Modal isOpen={data}>
        <ContentMensajeExito>
          <div>
            <i className={icono}></i>
          </div>
          <div>
            <h2>{titulo}</h2>
          </div>
          <div>
            <p>{message}</p>
          </div>

          <div className="row">
            <div className="col-md-6 mx-auto">
              <RedPrimaryButton link='/' texto='Ir al inicio' />
            </div>
          </div>


        </ContentMensajeExito>
          
      </Modal>
    </ContentModal>

  )
}

export default ModalLogOut