import React from 'react';

import { ContentMsgError, CardMensaje } from "./Estilos";

const ErrorView = ({logo}) => {
    return (
        <>
            <ContentMsgError>
                <div className="row">
                    <div className="col-10 mx-auto">
                        <CardMensaje>
                            <img src={logo} alt="Rueda Digital Logo" />
                            <h2>Lo sentimos</h2>
                            <p>Para tener una correcta visualización de este sitio debe ingresar de un dispositivo con una mayor resolución.</p>
                        </CardMensaje>
                    </div>
                </div>


            </ContentMsgError>
        
        </>

    )
}



export default ErrorView;