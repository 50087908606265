import styled, {keyframes} from 'styled-components';


const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity:1;
  }
`;

export const CardSinCategorias = styled.div `
    width: 80%;
    margin-left: 10%;
    margin-top: 5%;
    background: var(--Color-Blanco);
    border-radius: 24px;
    padding: 50px;
    box-shadow: 0px -1px 30px -4px rgba(127, 127, 127, 0.26);

    & > p{
        font-family: var(--Font-Display-Regular);
        color: var(--Color-Red-Primary);
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        margin-bottom: 0;
    }
`

export const CardCategoria = styled.div`
    width: 100%;
    background: var(--Color-Blanco);
    border-radius: 20px;
    padding: 25px 10px;
    box-shadow: 0px -1px 30px -4px rgba(127, 127, 127, 0.26);
`

export const ImagenCategoria = styled.div `
    background: #FFFFFF;
    border: 1.33333px solid #EBEBEB;
    box-sizing: border-box;
    box-shadow: 0px -1.33333px 40px -5.33333px rgba(127, 127, 127, 0.26);
    border-radius: 20px;
    text-align: center;
    padding: 10px 0;

    & > i {
        font-size: 60px;
        color: var(--Color-Red-Primary);
    }

    & > p {
        font-size: 12px;
        color: var(--Color-Red-Primary);
        font-family: var(--Font-Display-Regular);
    }
`

export const TituloSicoi = styled.p`
    margin-bottom: 2px;
    color: var(--Color-Grey-Dark);
    font-family: var(--Font-Display-Regular);
    font-size: 10px;

`

export const SicoiCategoria = styled.p`
    color: var(--Color-Red-Primary);
    font-family: var(--Font-Display-Regular);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
`

export const NombreCategoria = styled.p`
    color: var(--Color-Red-Negro);
    font-family: var(--Font-Display-Regular);
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
`

export const TituloInfoCategoria = styled.p`
    font-size: 14px;
    font-family: var(--Font-Display-Regular);
    color: var(--Color-Grey-Dark);
    margin-bottom: 0;
    margin-left: 5px;
`

export const ContentInfoCategoria = styled.div`
    width: 90%;
    border-radius: 14px;
    background: var(--Color-Grey);
    padding: 10px;
    border: 1px solid #BABABA;

    & > p {
        font-family: var(--Font-Display-Regular);
        font-weight: bold;
        color: var(--Color-Red-Primary);
        font-size: 16px;
        margin-left: 5px;
        margin-bottom: 0;
    }
`

export const ContentInputEditImagenCategoria = styled.div `
    padding: 20px;
    border: 1.37067px dashed #7F7F7F;
    background: var(--Color-Grey-Light);
    border-radius: 10px;
` 

export const LabelInputEditImagen = styled.label`
    background: var(--Color-Red-Primary);
    display: flex;
    justify-content: center;
    padding: 20px 10px;
    color: var(--Color-Blanco);
    width: 80%;
    margin: auto;
    border-radius: 10px;
    font-family: var(--Font-Display-Regular);
    transition: all .25s linear;
    font-size: 14px;
    &:hover{
        color: var(--Color-Red-Primary);
        background: var(--Color-Blanco);
    }
`

export const LeyendaEditImagen = styled.p`
    color: var(--Color-Grey-Dark);
    font-family: var(--Font-Display-Regular);
    font-size: 12px;
    margin: 1% 0;
    width: 90%;
    margin: 1% auto;

`


export const ContentMsgError = styled.div`
    animation: ${opacity} 1s ease;
    position: relative;
`

export const ErrorValueInput = styled.span`
    color: var(--Color-Red-Primary);
    font-family: var(--Font-Display-Regular);
    margin-left: 2%;
    font-weight:bold;
    font-size: 12px;
    
`

export const ContentFormActiveCategoria = styled.div`
    background: var(--Color-Grey-Light);
    border: 1px solid #BABABA;
    border-radius: 14px;
    width: 80%;
    padding: 10px 15px;
    transition: all .25s linear;
`

export const EstadoActivo = styled.p`
    font-family: var(--Font-Display-Bold);
    font-size: 14px;
    color: var(--Color-Green-Aproved);
    margin-bottom: 0;
    & > span {
        color: var(--Color-Negro);
    }

`

export const EstadoInactivo = styled.p`
    font-family: var(--Font-Display-Bold);
    font-size: 14px;
    color: var(--Color-Red-Primary);
    margin-bottom: 0;
    & > span {
        color: var(--Color-Negro);
    }

`


