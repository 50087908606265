import React, {useState} from 'react'

import {  
    active_BannerImagen,
    edit_BannerImagen,
    delete_BannerImagen
} from "../../services/BannersImagen";

import Loading from "../Loading/Loading";
import { LineSeparadora } from "../Content/Estilos";
import { ButtonSumitCambios, ButtonCancelCambios } from "../Buttons/Buttons";

import { 
    LabelInput,
    LabelInputImagen, 
    LeyendaInputImagen, 
    ContentMsgError, 
    ErrorValueInput 
} from "./Estilos";

import { EstadoActivo, EstadoInactivo } from "./Estilos";
import { ActivaElemento, InactivaElemento, DeleteElemento } from "../Buttons/Buttons";

import ModalExito from "../Modals/ModalExito";
import ModalError from "../Modals/ModalError";

const FormEditBannerImagen = ({banner, token}) => {

  
    const [loading, setLoading] = useState(false)
    const [Imagen, setImagen] = useState(false)
    const [currentImg, setCurrentImg] = useState('')

    const [errorInput, setErrorInput] = useState(false)

    const [msg, setMsg] = useState('')
    const [modalExito, setModalExito] = useState(false)
    const [modalError, setModalError] = useState(false)

    
    const changeImagen = (e) => {
        setLoading(true);
        const readerFile = new FileReader();
        readerFile.readAsDataURL(e[0]);
        readerFile.addEventListener("load", (result) => {
            setImagen(e)
            setCurrentImg(result.currentTarget.result)
            setErrorInput(false);
        });
        setLoading(false);
    };

    const active = async(token) => {
        setLoading(true)
        const data = await active_BannerImagen(banner.Id, token)

        if(data){
            if(banner.Activo === true){
                setMsg(`Se ha Desactivado el banner de imagen Id Ref: ${banner.Id} de forma correcta`)
            }else{
                setMsg(`Se ha Activado el banner de imagen Id Ref: ${banner.Id} de forma correcta`)
            }

            setModalExito(true)
        }else{
            setMsg('Ah ocurrido un error al intentar procesar tu solicitud')
            setModalError(true)
    
        }
        setLoading(false)
    }

    const deleteElement = async(token) => {
        setLoading(true)
        const data = await delete_BannerImagen(banner.Id, token)

        if(data){
            setMsg(`Hemos eliminado el Banner de Imagen Id Ref:  ${banner.Id} de forma correcta`)
            setModalExito(true)
        }else{
            setMsg('Ah ocurrido un error al intentar procesar tu solicitud')
            setModalError(true)
        }

        setLoading(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if(Imagen === false) {
          setErrorInput(true);
        }else{
            const result = await edit_BannerImagen(banner.Id, token, Imagen[0])
            if(result === true){
                setMsg('Hemos editado el Banner de Imagen de forma correcta')
                setModalExito(true)
            }else{
                setMsg('Ah ocurrido un error al intentar procesar tu solicitud')
                setModalError(true)
            }
        }

        setLoading(false);    
    };


    if(loading){
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="row mt-4 mb-4">
                <div className="col-md-11">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="row">
                            <div className="col-md-4 align-self-center">
                                
                                <LabelInputImagen htmlFor='imagen'>
                                    <i className="bi bi-arrow-up-short"></i>
                                    Seleccionar imagen
                                </LabelInputImagen>
                                
                                <input
                                    type="file"
                                    accept="image/*"
                                    name="imagen"
                                    id="imagen"
                                    className="input-file-imagen"
                                    onChange={(e) => changeImagen(e.target.files)}
                                />
                                
                                <LeyendaInputImagen>
                                    *La imagen a cargar debe tener una medida de 250x250 px con un peso máximo de 2 mb y un formato .png
                                </LeyendaInputImagen>

                                {errorInput === true && (
                                    <ContentMsgError>
                                        <ErrorValueInput className="bi bi-exclamation-triangle p-2 m-4">
                                            <span className="p-2"> No ha seleccionado ninguna imagen.</span>
                                        </ErrorValueInput>
                                    </ContentMsgError>
                                )}
                            </div>
                            <div className="col-md-8">
                            { currentImg === '' ?
                                <>
                                    <LabelInput>Imagen actual.</LabelInput>
                                    <img
                                    src={`https://api.ruedadigital.com.ar/${banner.URLImagen}`}
                                    className='w-100 mt-3'
                                    alt='Current Imagen'
                                    />
                                </>

                            :
                                <>
                                    <LabelInput>Imagen seleccionada.</LabelInput>
                                    <img
                                        src={currentImg}
                                        className='w-100 mt-3'
                                        alt='Current Imagen'
                                    />
                            
                                </>

                        
                            }
                            </div>        

                        </div>
                        <LineSeparadora />
                        <div className="row">

                            <div className="col-md-3 p-3">
                                <ButtonSumitCambios texto="Editar Banner" type="submit"  />
                            </div>
                            <div className="col-md-2 p-3">
                                <ButtonCancelCambios texto="Cancelar" type="button" />
                            </div>
                            <div className="col-md-7 border-left p-3 bg-grey" >
                                <div className="row">
                                    <div className="col-md-7 align-self-center">
                                        { banner.Activo === true ? 
                                            <div className='row '>
                                                <div className="col-md-5 align-self-center">
                                                    <EstadoActivo>
                                                        <span>Estado: </span> Activa
                                                    </EstadoActivo>
                                                </div>
                                                <div className="col-md-7">
                                                    <InactivaElemento 
                                                        type="button"
                                                        icono='bi bi-pause-btn'
                                                        texto='Pausar'
                                                        funcion={active}
                                                        parametro={token}
                                                    />

                                                </div>

                                            </div>

                                            :
                                            <div className="row">
                                                <div className="col-md-5 align-self-center">
                                                    <EstadoInactivo>
                                                        <span>Estado: </span> Pausada
                                                    </EstadoInactivo>
                                                </div>
                                                <div className="col-md-7">
                                                    <ActivaElemento
                                                        icono='bi bi-play'
                                                        texto='Activar'
                                                        funcion={active}
                                                        parametro={token}

                                                    />
                                                </div>
                                            </div>

                                        }
                                    </div>
                                    <div className="col-md-5">
                                        <DeleteElemento 
                                            texto="Eliminar"
                                            icono="bi bi-trash"
                                            funcion={deleteElement}
                                            parametro={token}
                                        />
                                    </div>

                                </div>
                            </div>
    
    
                        </div>

                    </form>
                </div>

            </div>

            <ModalExito 
                data={modalExito}
                icono='bi bi-check-circle'
                titulo='Todo ha ido bien.'
                message={`${msg}`}
            />

            <ModalError
                data={modalError}
                icono='bi bi-exclamation-triangle'
                titulo='Ups!'
                message={`${msg}`}
            />
        </>
    )
}

export default FormEditBannerImagen