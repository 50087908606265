import React from 'react'

import { LabelInput, ContentUnDatoUsuario}  from "./Estilos";
import { ReturnButton } from "../Buttons/Buttons";

const CardUnUsuario = ({usuario}) => {

  return (
    <>
        <div className="row p-3">
            <div className="col-md-2">
                <LabelInput>
                    ID Sicoi
                </LabelInput>
                <ContentUnDatoUsuario>
                    { usuario.Perfil ?
                        <p>{usuario.Perfil.NSicoi}</p>
                    :
                        <p className="sinDatoColumnaUsuario">Sin asignar.</p>
                    }
                </ContentUnDatoUsuario>
            </div>

            <div className="col-md-4 border-left-grey">
                <LabelInput>
                    Nombre y Apellido
                </LabelInput>
                <ContentUnDatoUsuario>
                    <p>{usuario.Cliente.Nombre} {usuario.Cliente.Apellido}</p>
                </ContentUnDatoUsuario>
            </div>

            <div className="col-md-4 border-left-grey">
                <LabelInput>
                    Correo Electronico
                </LabelInput>
                <ContentUnDatoUsuario>
                    <p>{usuario.Cliente.CorreoElectronico}</p>
                </ContentUnDatoUsuario>
            </div>

            <div className="col-md-2 border-left-grey">
                <LabelInput>
                    Fecha Nacimiento
                </LabelInput>
                <ContentUnDatoUsuario>
                    <p>{usuario.Cliente.FechaNacimiento}</p>
                </ContentUnDatoUsuario>
            </div>
        </div>

        <div className="row  p-3">
            <div className="col-md-3">
                <LabelInput>
                    Condicion Frente al IVA
                </LabelInput>
                <ContentUnDatoUsuario>
                    { usuario.Perfil ?
                            <p>{usuario.Perfil.CondicionFrenteAlIVA.CondicionFrenteAlIva}</p>
                        :
                            <p className="sinDatoColumnaUsuario">Sin datos.</p>
                    }
                </ContentUnDatoUsuario>
            </div>
            <div className="col-md-3 border-left-grey">
                <LabelInput>
                    CUIT
                </LabelInput>
                <ContentUnDatoUsuario>
                    { usuario.Perfil ?
                        <p>{usuario.Perfil.CUIL}</p>
                        :
                        <p className="sinDatoColumnaUsuario">Sin datos.</p>
                    }
                </ContentUnDatoUsuario>
            </div>

            <div className="col-md-6 border-left-grey">
                <LabelInput>
                    Razón Social
                </LabelInput>
                <ContentUnDatoUsuario>
                    { usuario.Perfil ?
                        <p>{usuario.Perfil.RazonSocial}</p>
                        :
                        <p className="sinDatoColumnaUsuario">Sin datos.</p>
                    }
                </ContentUnDatoUsuario>
            </div>
        </div>

        <div className="row  p-3">

            <div className="col-md-5">
                <LabelInput>
                    Domicilio
                </LabelInput>
                <ContentUnDatoUsuario>
                    { usuario.Perfil ?
                        <p>{usuario.Perfil.Domicilio}</p>
                        :
                        <p className="sinDatoColumnaUsuario">Sin datos.</p>
                    }
                </ContentUnDatoUsuario>
            </div>
            <div className="col-md-5 border-left-grey">
                <LabelInput>
                    Telefono
                </LabelInput>
                <ContentUnDatoUsuario>
                    <p>{usuario.Cliente.Telefono}</p>
                </ContentUnDatoUsuario>
            </div>

            <div className="col-md-2 border-left-grey">
                <LabelInput>
                    Estado
                </LabelInput>
                <ContentUnDatoUsuario>
                    { usuario.Perfil.ValidPerfil ?
                        <>
                            { usuario.Perfil.ValidPerfil === true ?
                                <p className="text-green">Activo</p>
                                :
                                <p className="text-alert">Inactivo</p>
                            }
                        </>
                        :
                        <p className="sinDatoColumnaUsuario">Sin asignar.</p>
                    }   
                </ContentUnDatoUsuario>

            </div>
        </div>

        <hr />

        <div className="row">

            <div className="col-md-3">
               
            <ReturnButton />
            </div>
            <div className="col-md-6"></div>
        </div>
    
    </>


    
  )
}

export default CardUnUsuario