import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { get_ProductoBySicoi } from "../services/Productos";

import Loading from "../components/Loading/Loading";
import Navegacion from "../components/Navegacion/Navegacion";

import { TituloSeccion, ItemSeccion } from "../components/Titulos/Titulos";
import { ContentSeccion, LineSeparadora } from "../components/Content/Estilos";

import FormImagenesProductos from "../components/CardsProductos/FormImagenesProductos";
import SinDatos from "../components/Errors/SinDatos";
import { get_Token } from "../helpers/getToken";

const ProductoVerImagenes = ({title}) => {

  const token = get_Token()

  const {sicoi} = useParams()
 
  const [columns, setColummns] = useState(false);
  const [loading, setLoading] = useState(true)
  const [producto, setProducto] = useState(null);
  

  useEffect(()=>{
    const getData = async(sicoi) =>{
        setLoading(true)
        const data = await get_ProductoBySicoi(sicoi)
        if(data){
            setProducto(data)
        }else{
            setProducto(null)
        }

        setLoading(false)
    }
    getData(sicoi)
},[sicoi])

  if(loading){
    return(
      <Loading />
    )
  }
  
  return (
    <div className="row">
    <div className={!columns ? "col-md-2 layout-general" : "col-md-1 layout-general"}>
        <Navegacion hideSide={setColummns} active="/Productos" />
    </div>

    <div className={ !columns ? "col-md-10 layout-general" : "col-md-11 layout-general"}>
        <TituloSeccion title={title} />

        <ContentSeccion>
            { producto === null ?
                <>
                    <SinDatos title='Ups ...' message={`No encontramos datos asociados al producto N° Sicoi Ref: ${sicoi}`} />
                </>
                :
                <>
                    <ItemSeccion title={`Producto: ${producto.Nombre} `} />
                    <div className="mt-4 mb-4">
                      <FormImagenesProductos producto={producto} token={token} />
                      <LineSeparadora />
                    </div>
                </>

            }


        </ContentSeccion>
    </div>
</div>
  )
}

export default ProductoVerImagenes