import axios from "axios";
import { EndPointApi, Credencials } from "./config";

const get_ProductosByPage = async(page) => {
    try {
        const {data} = await axios({
            method: 'GET',
            url: `${EndPointApi}/Productos/getAll/${page}`,
            auth: Credencials
        })

        
        if(data.status === 'OK'){
            return data.data
        }

        return null
        
    } catch (error) {
        return null
    }
}

// Obtiene todos los Productos
export const get_AllProductos = async() => {
    let page = 1
    const productos = []
    let productosEsperados = 50

    while (productosEsperados === 50) {
 
        const result = await get_ProductosByPage(page)
        for (const unProducto of result) {
            productos.push(unProducto)
        }
        page = page + 1
        productosEsperados = result.length
        
    }
    return productos

}

// Obtiene un producto por su Sicoi
export const get_ProductoBySicoi = async(sicoi) => {
    try {
        const {data} = await axios({
            url: `${EndPointApi}/Producto/getBySicoi/${sicoi}`,
            auth: Credencials
        })

        if(data.status === 'OK'){
            return data.data
        }

        return null
        
    } catch (error) {
        return null
    }
}

// Agrega una descripcion de un Producto
export const edit_DescripcionProducto = async(sicoi, token, body) => {
    try {
        const {data} = await axios({
            method:'PUT',
            url:`${EndPointApi}/Descripcion/addOrEdit/${sicoi}`,
            auth: Credencials,
            headers:{
                "Auth-Token": token
            },
            data: body,
        })
        
        if(data.status === 'OK'){
            return data.data
        }

        return null
        
    } catch (error) {
        return null
    }

}


// Agrega una imagen a un producto
export const add_ImagenProducto = async(sicoi, token, imagen) => {
    try {
        const body = new FormData()
        body.append('imagen', imagen)
        const {data} = await axios({
            method:'POST',
            url:`${EndPointApi}/Imagenes/addOne/${sicoi}`,
            auth: Credencials,
            headers:{
                "Auth-Token": token
            },
            data:body
        })

        if(data.status === 'OK'){
            return true
        }

        return null
        

    } catch (error) {
        return null
    }
}

// Edita una imagen de un producto
export const edit_ImagenProducto = async(idImagen, token, imagen) => {
    try {
        const body = new FormData()
        body.append('imagen', imagen)
        const {data} = await axios({
            method:'PUT',
            url:`${EndPointApi}/Imagenes/editOne/${idImagen}`,
            auth: Credencials,
            headers:{
                "Auth-Token": token
            },
            data:body
        })

        if(data.status === 'OK'){
            return true
        }

        return null
        

    } catch (error) {
        return null
    }
}

// Edita una imagen de un producto
export const delete_ImagenProducto = async(idImagen, token) => {
    try {

        const {data} = await axios({
            method:'DELETE',
            url:`${EndPointApi}/Imagenes/deleteOne/${idImagen}`,
            auth: Credencials,
            headers:{
                "Auth-Token": token
            }
        })
        if(data.status === 'OK'){
            return true
        }

        return null
        

    } catch (error) {
        return null
    }
}



