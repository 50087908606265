import axios from "axios";
import { EndPointApi, Credencials } from "./config";

export const login_Adm = async (body) => {
    try {

        const data = await axios({
            method:'POST',
            url:`${EndPointApi}/UserAdm/login`,
            auth: Credencials,
            data:body

        })
        if(data.status === 200){
            if(data.data.data.token){
                return data.data.data.token
            }
            
        }


        return null
        
    } catch (error) {
        return null
    }
}