import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { get_OrdenByHash } from "../services/Ordenes";

import Loading from "../components/Loading/Loading";
import Navegacion from "../components/Navegacion/Navegacion";

import { TituloSeccion, ItemSeccion } from "../components/Titulos/Titulos";
import { ContentSeccion } from "../components/Content/Estilos";
import OrdenesDetalles from "../components/CardsOrdenes/CardUnaOrden";

import { ReturnButton } from "../components/Buttons/Buttons";
import { CardSinDatos } from "../components/CardsBanners/Estilos";
import { get_Token } from '../helpers/getToken';


const UnaOrden = ({ title }) => {

    const { hash } = useParams()

    const [columns, setColummns] = useState(false)
    const [loading, setLoading] = useState(true)
    const [orden, setOrden] = useState()


    const token = get_Token()

    useEffect(() => {
        const getData = async (token) => {
            setLoading(true)
            const data = await get_OrdenByHash(token, hash)
            if (data) {
                setOrden(data)
            } else {
                setOrden(null)
            }
            setLoading(false)
        }
        getData(token)
    }, [])

    if (loading) {
        return (
            <Loading />
        )
    }




    return (
        <div className="row">

            <div className={!columns ? 'col-md-2 layout-general' : 'col-md-1 layout-general'}>
                <Navegacion hideSide={setColummns} active='/Ordenes' />
            </div>

            <div className={!columns ? 'col-md-10 layout-general' : 'col-md-11 layout-general'}>
                <TituloSeccion title={title} />

                <ContentSeccion>


                    {
                        orden ?
                            <>
                                <ItemSeccion title={`Ver orden - Id e-commerce: ${orden.Orden.Id}`} />
                                <OrdenesDetalles orden={orden} />
                                <hr />
                                <div className="row mt-4 p-4">
                                    <div className="col-md-4 mx-auto">
                                        <ReturnButton />
                                    </div>
                                </div>
                            </>
                            :
                            <CardSinDatos>
                                <p>Al parecer la orden que estas buscando no esta disponible. <br /></p>
                                <div className="row p-4">
                                    <div className="col-md-4 mx-auto">
                                        <ReturnButton />
                                    </div>
                                </div>
                            </CardSinDatos>
                    }
                </ContentSeccion>


            </div>

        </div>

    )

}

export default UnaOrden