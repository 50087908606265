import React, {useState} from 'react'

import { add_BannerImagen } from "../../services/BannersImagen";

import Loading from "../Loading/Loading";
import { LineSeparadora } from "../Content/Estilos";
import { ButtonSumitCambios, ButtonCancelCambios } from "../Buttons/Buttons";

import { 
    LabelInput,
    LabelInputImagen, 
    LeyendaInputImagen, 
    SinImagenCategoria, 
    ContentMsgError, 
    ErrorValueInput 
} from "./Estilos";

import ModalExito from "../Modals/ModalExito";
import ModalError from "../Modals/ModalError";

const FormAddBannerImagen = ({token}) => {

    const [loading, setLoading] = useState(false)
    const [Imagen, setImagen] = useState(false)
    const [currentImg, setCurrentImg] = useState('')
    const [errorInput, setErrorInput] = useState(false);
    const [modalExito, setModalExito] = useState(false)
    const [modalError, setModalError] = useState(false)

    const changeImagen = (e) => {
        setLoading(true);
        const readerFile = new FileReader();
        readerFile.readAsDataURL(e[0]);
        readerFile.addEventListener("load", (result) => {
            setImagen(e)
            setCurrentImg(result.currentTarget.result)
            setErrorInput(false);
        });
        setLoading(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if(Imagen === false) {
          setErrorInput(true);
        }else{
            const result = await add_BannerImagen(token, Imagen[0])
            if(result === true){
                setModalExito(true)
            }else{
                setModalError(true)
            }
        }
        setLoading(false);    
    };

    

    if(loading){
        return (
            <Loading />
        )
    }


    return (
        <>
            <div className="mt-4">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="row">
                            <div className="col-md-4 align-self-center">
                                <LabelInputImagen htmlFor='imagen'>
                                    <i className="bi bi-arrow-up-short"></i>
                                    Seleccionar imagen
                                </LabelInputImagen>

                                <input
                                    type="file"
                                    accept="image/*"
                                    name="imagen"
                                    id="imagen"
                                    className="input-file-imagen"
                                    onChange={(e) => changeImagen(e.target.files)}
                                />
                                { currentImg === '' ?
                                    <>
                                    </>
                                    :
                                    <LeyendaInputImagen>
                                        *La imagen a cargar debe tener una medida de 250x250 px con un peso máximo de 2 mb y un formato .png
                                    </LeyendaInputImagen>
                                }
                                {errorInput === true && (
                                    <ContentMsgError>
                                        <ErrorValueInput className="bi bi-exclamation-triangle p-2 m-4">
                                            <span className="p-2"> No ha seleccionado ninguna imagen.</span>
                                        </ErrorValueInput>
                                    </ContentMsgError>
                                )}

                            </div>
                            <div className="col-md-8 p-4">

                                { currentImg === '' ?
                                    <SinImagenCategoria>
                                        <i className="bi bi-camera"></i>
                                        <p>No haz seleccionado ninguna imagen</p>

                                        <LeyendaInputImagen>
                                            *La imagen a cargar debe tener una medida de 250x250 px con un peso máximo de 2 mb y un formato .png
                                        </LeyendaInputImagen>
                                    </SinImagenCategoria>
                                    :
                                    <>
                                        <LabelInput>Imagen seleccionada.</LabelInput>
                                        <img
                                            src={currentImg}
                                            className='w-100'
                                            alt='Current Imagen'
                                        />
                                    </>


                                }

                            </div>
                        </div>
                        <LineSeparadora />

                        <div className="row p-2">
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                <ButtonSumitCambios texto="Agregar Banner" type="submit" />
                            </div>
                            <div className="col-md-3">
                                <ButtonCancelCambios texto="Cancelar" type="button" />
                            </div>
                            <div className="col-md-3"></div>
                        </div>




                </form>
            </div>

            <ModalExito 
                data={modalExito}
                icono='bi bi-check-circle'
                titulo='Todo ha ido bien.'
                message={`Se ha agregado un banner de Imagen de forma correcta.`}
            />

            <ModalError
                data={modalError}
                icono='bi bi-exclamation-triangle'
                titulo='Ups!'
                message={`Ah ocurrido un error al intentar realizar la operación solicitada.`}
            />
        </>

    )
}

export default FormAddBannerImagen