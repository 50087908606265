import React from 'react'

import NotFoundImage from "../../assets/Images/notFound.svg";

import { 
    ContentMsgError,
    CardMensaje
} from "./Estilos";

import { RedPrimaryButton } from "../Buttons/Buttons";

const NotFound = ({logo, title, message, link, texto}) => {
  return (
    <>
        <ContentMsgError>
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <CardMensaje>
                        <img src={logo} alt="Rueda Digital Logo" />
                        <h2>
                            {title}
                        </h2>
                        <p>
                            {message}
                        </p>
                        <br />
                        <div className="row">
                            <div className="col-md-6">
                                <RedPrimaryButton link={link} texto={texto}/>
                            </div>
                        </div>
                    </CardMensaje>
                </div>
                <div className="col-md-6">
                    <img src={NotFoundImage} className='w-100' alt="No encontrado" />
                </div>

            </div>
        </div>
        </ContentMsgError>
    </>
  )
}

export default NotFound