import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const CardsUsuarios = ({usuario}) => {
    const [drop, setDrop] = useState(false)
  return (
    <div className={usuario.Id % 2 === 0 ? 'row mt-2 mb-2 text-center  p-4': 'row mt-2 mb-2 text-center bg-grey p-4' }>
                    
        <div className="col-md-2 align-self-center">
        <p className="datoColumnaUsuario">
            {usuario.Cliente.Nombre} {usuario.Cliente.Apellido}
        </p>
        </div>
        
        <div className="col-md-2 align-self-center">
            {
                usuario.Perfil ?

                <p className="datoColumnaUsuario">
                    {usuario.Perfil.RazonSocial}
                </p>
            :
                <p className="sinDatoColumnaUsuario">
                    Sin datos.
                </p>
            }


        </div>

        <div className="col-md-2 align-self-center">
            {
                usuario.Perfil ?

                <p className="datoColumnaUsuario">
                    {usuario.Perfil.CUIL}
                </p>
            :
                <p className="sinDatoColumnaUsuario">
                    Sin datos.
                </p>
            }
        </div>

        <div className="col-md-2 align-self-center">
            {
                usuario.Perfil ? 

                <p className="datoColumnaUsuario">
                    {usuario.Perfil.CondicionFrenteAlIVA.CondicionFrenteAlIva}
                </p>
            :
                <p className="sinDatoColumnaUsuario">
                    Sin datos.
                </p>
            }
        </div>

        <div className="col-md-2 align-self-center">
            {
                usuario.Perfil ? 

                <p className="datoColumnaUsuario">
                    {usuario.Perfil.NSicoi}
                </p>
            :
                <p className="sinDatoColumnaUsuario">
                    Sin asignar.
                </p>
            }
        </div>

        <div className="col-md-1 align-self-center">
            {
                usuario.Perfil ? 
                    <>
                    {
                        usuario.Perfil.ValidPerfil === true ?
                        <p className="datoColumnaUsuario text-green">
                            Activo
                        </p>
                        :
                        <p className="datoColumnaUsuario text-alert">
                            Inactivo
                        </p>
                        
                    }
                    
                    </>


                :
                    <p className="sinDatoColumnaUsuario">
                        Sin asignar.
                    </p>
            }
        </div>
        
        <div className="col-md-1 align-self-center">
            <ButtonDropdown isOpen={drop} onClick={()=>setDrop(!drop)} >
                <DropdownToggle  color='secondary'>
                <i className="bi bi-three-dots-vertical"></i>
                </DropdownToggle>
                <DropdownMenu className='contentLinkOpcionUsuario'>
                    <DropdownItem header>
                        <Link to={`/Clientes/${usuario.Cliente.HashCliente}`} className='linkOpcionUsuario'>
                            Ver Cliente
                        </Link>
                    </DropdownItem>
                    {/* <DropdownItem header>
                        <Link to={`/Ordenes/Cliente/${usuario.Cliente.HashCliente}`} className='linkOpcionUsuario'>
                            Ver compras
                        </Link>
                    </DropdownItem> */}
                </DropdownMenu>
            </ButtonDropdown>
        </div> 
    </div>
  )
}

export default CardsUsuarios