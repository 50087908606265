import React from "react";
import { Link } from "react-router-dom";

import {  
    ContentButtonPrimary,
    RedPrimaryButtonStyles,
    ContentButtonSecondary,
    SecondaryButtonStyles,
    SecondaryButtonEditarStyles,
    SecondaryButtonIrAlInicioStyles,
    ConfirmButtonStyles,
    CancelButtonStyles,
    ActivaElementosStyles,
    PausaElementosStyles,
    DeleteElementosStyles
} from "./Estilos";

export const RedPrimaryButton = ({link, texto}) => {

    return (
        <ContentButtonPrimary>
            <Link to={link}>
                <RedPrimaryButtonStyles>
                    {texto}
                </RedPrimaryButtonStyles>
            </Link>
        </ContentButtonPrimary>

    )

}

export const ReturnButton = () => {

    const historyBack = () => {
        window.history.back()
    }

    return (
        <ContentButtonSecondary>

                <SecondaryButtonStyles onClick={()=> historyBack()}>
                    Volver Atras
                </SecondaryButtonStyles>

        </ContentButtonSecondary>
    )
}

export const SecondaryButtonEditar = ({link, texto}) => {
    return(
        <ContentButtonSecondary>
            <Link to={link}>
                <SecondaryButtonEditarStyles >
                    {texto}
                </SecondaryButtonEditarStyles>
            </Link>
        </ContentButtonSecondary>

    )

}

export const SecondaryButtonIrAlInicio = () => {
    return(
        <ContentButtonSecondary>
            <Link to='/Inicio'>
                <SecondaryButtonIrAlInicioStyles >
                    Ir al inicio
                </SecondaryButtonIrAlInicioStyles>
            </Link>
        </ContentButtonSecondary>

    )

}

export const ButtonSumitCambios = ({texto, type}) => {
    return (
        <ContentButtonPrimary>
            <ConfirmButtonStyles type={type}>
                {texto}
            </ConfirmButtonStyles>
        </ContentButtonPrimary>
    )
}

export const ButtonCancelCambios = ({texto, type}) => {
    
    const historyBack = () => {
        window.history.back()
    }

    return (
        <ContentButtonPrimary>
            <CancelButtonStyles type={type} onClick={()=> historyBack()}>
                {texto}
            </CancelButtonStyles>
        </ContentButtonPrimary>
    )
}

export const ActivaElemento = ({texto, icono, funcion, parametro}) => {
    return (
        <ContentButtonPrimary>
            <ActivaElementosStyles
                type="button"
                onClick={()=>funcion(parametro)}
            >
                <i className={`${icono} p-2`}></i>
                {texto}
            </ActivaElementosStyles>
        </ContentButtonPrimary>
    )
}

export const InactivaElemento = ({texto, icono, funcion, parametro}) => {
    return (
        <ContentButtonPrimary>
            <PausaElementosStyles
                type="button"
                onClick={()=>funcion(parametro)}
            >
                <i className={`${icono} p-2`}></i>
                {texto}
            </PausaElementosStyles>
        </ContentButtonPrimary>
    )
}

export const DeleteElemento = ({icono, texto, funcion, parametro}) => {
    return (
        <ContentButtonPrimary>
            <DeleteElementosStyles
                type="button"
                onClick={()=>funcion(parametro)}
            >
                <i className={`${icono} p-2`}></i>
                {texto}
            </DeleteElementosStyles>
        </ContentButtonPrimary>
    )
}

export const OpcionButton = ({link, texto}) => {
    return (
        <ContentButtonSecondary>    
            <Link to={link}>
                <SecondaryButtonStyles>
                    {texto}
                </SecondaryButtonStyles>
            </Link>
        </ContentButtonSecondary>
    )
}