import styled,{keyframes} from 'styled-components';

const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity:1;
  }
`;

export const ContentGralLoginForm = styled.div`
    background: #F9F9F9;
    /* background: radial-gradient(91.5% 210.93% at -13.44% 132.89%, #F9F9F9 0%, rgba(196, 196, 196, 0) 100%); */
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
`

export const CardFormLogin = styled.div`
    width: 60%;
    margin-left: 20%;
    background: var(--Color-Blanco);
    padding: 45px 100px;
    box-shadow: 0px 4px 30px -4px rgba(104, 117, 130, 0.2);
    border-radius: 24px;
    height: 95vh;
    max-height: 95vh;
`

export const ImagenLogo = styled.img`
    width: 40%;
    padding: 0px 40px 0px 0px;
`

export const CapsulaPanel = styled.div`
    background: var(--Color-Negro);
    width: 35%;
    border-radius: 44px;
    padding: 8px 0px;
    font-family: var(--Font-Display-Regular);
    font-style: normal;
    font-size: 12px;
    letter-spacing: 2px;
    text-align: center;
    color: var(--Color-Blanco);
`

export const ContentTitulosSubtitulo = styled.div`
    margin-top: 3%;

    & > h1{
        font-family: var(--Font-Roboto-Regular);
        color: var(--Color-Red-Primary);
        font-weight: 700;
        letter-spacing: 2px;
        font-size: 1.5rem;
    }

    & > p{
        font-family: var(--Font-Display-Regular);
        font-size: .9rem;
        margin-bottom:0 !important;
    }

`

export const LabelForm = styled.label`
    font-family: var(--Font-Display-Regular);
    font-size: 14px;
    color: var(--Color-Grey-Dark);
    width: 90%;
    margin-left: 2%;
`

export const ContentMsgError = styled.div`
    animation: ${opacity} 1s ease;
    position: relative;
`

export const ErrorValueInput = styled.span`
    color: var(--Color-Red-Primary);
    font-family: var(--Font-Display-Regular);
    margin-left: 2%;
    font-weight:bold;
    font-size: 12px;
    
`



export const BotonIngresar = styled.button`
    width: 30%;
    padding: 20px 15px;
    margin-left: 5%;
    background: var(--Color-Red-Primary);
    font-family: var(--Font-Display-Regular);
    color: var(--Color-Blanco);
    transition: all .25s linear;
    border: none;
    border-radius: 14px;
    &:hover{
        background: radial-gradient(91.5% 210.93% at -13.44% 132.89%, #F9F9F9 0%, rgba(196, 196, 196, 0) 100%);
        color: var(--Color-Red-Primary);
    }
`

