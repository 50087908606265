import React, {useState} from 'react'

import Navegacion from "../components/Navegacion/Navegacion";

import { TituloSeccion, ItemSeccion } from "../components/Titulos/Titulos";
import { ContentSeccion} from "../components/Content/Estilos";

import FormAddBannerImagen from "../components/CardsBanners/FormAddBannerImagen";
import { get_Token } from '../helpers/getToken';

const BannerImagenAgregar = ({title}) => {
    const [columns, setColummns] = useState(false)
    const token = get_Token()

    return (
        <div className="row">
            <div className={!columns ? 'col-md-2 layout-general' : 'col-md-1 layout-general'}>
                <Navegacion hideSide={setColummns} active='/Banners_imagen' />
            </div>
            <div className={!columns ? 'col-md-10 layout-general' : 'col-md-11 layout-general'}> 
                
                <TituloSeccion title={title} />

                <ContentSeccion>
                    <ItemSeccion title='Agregar un Banner' />
                    
                    <FormAddBannerImagen token={token} />


                </ContentSeccion>
            
            </div>

        </div>
    )
}

export default BannerImagenAgregar