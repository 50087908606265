import axios from "axios";
import { EndPointApi, Credencials } from "./config";

export const get_DataPanel = async (token) => {
    try {
        const {data} = await axios({
            method:'GET',
            url:`${EndPointApi}/panelAdm`,
            auth: Credencials,
            headers:{
                "Auth-Token": token,
            }
        })

        return data.data
        
    } catch (error) {
        return null
    }
}

export const get_DataDolar = async () => {
    try {
        const {data} = await axios({
            method:'POST',
            url:`${EndPointApi}/getCotizacionUSD`,
            auth: Credencials,
        })

        if(data){
            return data
        }

        return null

    } catch (error) {
        return null
    }
}