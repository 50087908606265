import React, {useState, useEffect} from 'react'

import { edit_DescripcionProducto } from "../../services/Productos";


import { LabelInput, InputDescripcionProducto, InputDescripcionProductoReadOnly, TextAreaDescripcionProducto } from "./Estilos";
import { ButtonSumitCambios, ButtonCancelCambios } from "../Buttons/Buttons";

import ModalExito from "../Modals/ModalExito";
import ModalError from "../Modals/ModalError";
import Loading from "../Loading/Loading";

const FormDescripcionProducto = ({producto, token}) => {

    const [largo, setLargo] = useState('')
    const [ancho, setAncho] = useState('')
    const [composicion, setComposicion] = useState('')
    const [peso, setPeso] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [observaciones, setObservaciones] = useState('')


    const [modalExito, setModalExito] = useState(false)
    const [modalError, setModalError] = useState(false)
    const [msg, setMsg] = useState('')

    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        const estadosIniciales = async (producto) => {
            if(producto.Descripcion !== null){
                setLargo(producto.Descripcion.LargoRollo)
                setAncho(producto.Descripcion.AnchoRollo)
                setComposicion(producto.Descripcion.ComposicionRollo)
                setPeso(producto.Descripcion.PesoRollo)
                setDescripcion(producto.Descripcion.Descripcion)
                setObservaciones(producto.Descripcion.Observaciones)
            }
        }

        estadosIniciales(producto)
    },[producto])


    const checkBody =(datos) => {
        if(datos === ''){
            return false
        }else{
            return true
        }
    }

    const HandleSubmit = async(e) => {
        e.preventDefault();
        setLoading(true)
        const parametros = [largo, ancho, composicion, peso, descripcion, observaciones]
        const checkParametros = parametros.map(unParametro => checkBody(unParametro))

        const check =  checkParametros.includes(true)

        if(check === false){
            window.alert('Debes completar al menos un campo.')
        }else{
            const body = {
                LargoRollo: largo,
                AnchoRollo: ancho,
                ComposicionRollo: composicion,
                PesoRollo: peso,
                Descripcion: descripcion,
                Observaciones: observaciones
            }

            const data = await edit_DescripcionProducto(producto.CodigoSicoi, token, body)

            if(data){
                setMsg(`Se ha editado la descripción del Producto ${producto.Nombre}`)
                setModalExito(true)
            }else{
                setMsg('Ah ocurrido un error al intentar procesar tu solicitud')
                setModalError(true)
            }
        }

        setLoading(false)

      
    }

    if(loading){
        return (
            <Loading />
        )
    }


    

  return (
    <>
        <form onSubmit={HandleSubmit}>
            <div className="row mt-2 mb-4">
                <div className="col-md-4">

                    <LabelInput>Nombre</LabelInput>
                    <InputDescripcionProductoReadOnly 
                        readOnly
                        value={producto.Nombre}
                    />
                    


                </div>
                <div className="col-md-4">
                    <LabelInput>Categoria</LabelInput>
                    <InputDescripcionProductoReadOnly 
                        readOnly
                        value={producto.Grupo.Nombre}
                    />

                </div>
                <div className="col-md-4">
                    <LabelInput>Codigo Sicoi</LabelInput>
                    <InputDescripcionProductoReadOnly 
                        readOnly
                        value={producto.CodigoSicoi}
                    />

                </div>
            </div>
            <div className="row mb-4">
                <div className="col-md-6 mx-auto">
                    <hr />
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-3">
                    <LabelInput htmlFor='Largo'>Largo</LabelInput>
                    <InputDescripcionProducto
                        value={largo}
                        onChange={(e)=>setLargo(e.target.value)}
                        placeholder='Largo del Rollo'
                        id='Largo'
                    />
                </div>
                <div className="col-md-3">
                    <LabelInput htmlFor='Ancho'>Ancho</LabelInput>
                    <InputDescripcionProducto
                        value={ancho}
                        onChange={(e)=>setAncho(e.target.value)}
                        placeholder='Ancho del Rollo'
                        id='Ancho'
                    />
                </div>
                <div className="col-md-3">
                    <LabelInput htmlFor='Composicion'>Composición</LabelInput>
                    <InputDescripcionProducto
                        value={composicion}
                        onChange={(e)=>setComposicion(e.target.value)}
                        placeholder='Composición del Rollo'
                        id='Composicion'
                    />
                </div>
                <div className="col-md-3">
                    <LabelInput htmlFor='Peso'>Peso</LabelInput>
                    <InputDescripcionProducto
                        value={peso}
                        onChange={(e)=>setPeso(e.target.value)}
                        placeholder='Peso del Rollo'
                        id='Peso'
                    />
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-md-6 mx-auto">
                    <hr />
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-md-1"></div>
                <div className="col-md-5">
                    <LabelInput htmlFor='Descripcion'>Descripción</LabelInput>
                    <TextAreaDescripcionProducto
                        rows={5}
                        placeholder='Descripción del Rollo'
                        id='Descripcion'
                        value={descripcion}
                        onChange={(e) => setDescripcion(e.target.value)}
                    >

                    </TextAreaDescripcionProducto>
                </div>
                <div className="col-md-5">
                    <LabelInput htmlFor='Observaciones'>Observaciones</LabelInput>
                    <TextAreaDescripcionProducto
                        rows={5}
                        placeholder='Observaciones del Rollo'
                        id='Observaciones'
                        value={observaciones}
                        onChange={(e) => setObservaciones(e.target.value)}
                    >

                    </TextAreaDescripcionProducto>
                </div>
                <div className="col-md-1"></div>
            </div>

            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-3">
                    <ButtonSumitCambios texto="Editar Descripcion" type="submit"  />
                </div>
                <div className="col-md-3">
                    <ButtonCancelCambios texto="Cancelar" type="button" />
                </div>
                <div className="col-md-3"></div>
            </div>

        </form>
    
        <ModalExito 
            data={modalExito}
            icono='bi bi-check-circle'
            titulo='Todo ha ido bien.'
            message={`${msg}`}
        />

        <ModalError
            data={modalError}
            icono='bi bi-exclamation-triangle'
            titulo='Ups!'
            message={`${msg}`}
        />
    </>
  )
}

export default FormDescripcionProducto