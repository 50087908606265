import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import Cookies from "universal-cookie";

import logo from "../../assets/Logos/logo.svg";
import logoReducido from "../../assets/Logos/logoMin.svg";

import ModalExito from "../Modals/ModalLogOut";
import { 
    ContentLogo,
    ItemExpand,
    ItemSimple,
    // ItemSimpleFixed
} from "./Estilos";

const SidebarData = [
    {
        id: 1,
        cName: 'nav-text',
        path: '/Inicio',
        icon: 'bi bi-house-door',
        title: 'Inicio',
    },
    {
        id: 2,
        cName: 'nav-text',
        path: '/Categorias',
        icon: 'bi bi-grid',
        title: 'Categorias',
    },
    {
        id: 3,
        cName: 'nav-text',
        path: '/Productos',
        icon: 'bi bi-layers',
        title: 'Productos',
    },
    {
        id: 4,
        cName: 'nav-text',
        path: '/Clientes',
        icon: 'bi bi-people',
        title: 'Clientes',
    },
    {
        id: 5,
        cName: 'nav-text',
        path: '/Ordenes',
        icon: 'bi bi-clipboard-minus',
        title: 'Ordenes',
    },
    {
        id: 6,
        cName: 'nav-text',
        path: '/Banners_imagen',
        icon: 'bi bi-image',
        title: 'Banners imagen',
    },
    {
        id: 7,
        cName: 'nav-text',
        path: '/Banners_texto',
        icon: 'bi bi-exclamation-circle',
        title: 'Banners texto',
    },



]

const cookies = new Cookies()

const Navegacion = ({ active}) => {
    const sidebar = useState(true);
    const [modalExito, setModalExito] = useState(false)



    const cerrarSesion = () => {
        cookies.remove('admin_rueda', {path: "/"})
        setModalExito(true)
    }

    if(modalExito){
        return(
            <ModalExito 
                data={modalExito}
                icono='bi bi-check-circle'
                titulo='Todo ha ido bien.'
                message={`Hemos cerrado tu sesión de forma correcta.`}
            />
        )
    }
    if(sidebar){
        return(
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                <ContentLogo>
                    <img src={logo} alt="Logo Rueda" />
                </ContentLogo>
            
                <ul className='nav-menu-items mt-4'>


                    {SidebarData.map((item, index) => (
                        <ItemExpand key={index} className={item.path === active ? 'active' : item.cName }>
                            <Link to={item.path}>
                                <i className={item.icon}></i>
                                <span>{item.title}</span>
                            </Link>
                        </ItemExpand>
                    ))}
                    <hr className='bg-white' />

                    <ItemExpand  onClick={()=>cerrarSesion()}>
                        <Link to='#' className='menu-bars'>
                            <i className="bi bi-box-arrow-left"></i>
                            <span>Cerrar Sesión</span>
                        </Link>
                    </ItemExpand>
            
                </ul>
            </nav>
        )
    }

    return (
        <>
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                <ContentLogo>
                    <img src={logoReducido} alt="Logo Rueda" />
                </ContentLogo>
    
                <ul className='nav-menu-items mt-4'>


                    {SidebarData.map((item, index) => (
                        <ItemSimple key={index} className={item.path === active ? 'active' : item.cName }>
                            <Link to={item.path}>
                                <i className={item.icon}></i>
                            </Link>
                        </ItemSimple>
                    ))}
                    <hr />

                    {/* <ItemSimpleFixed  onClick={()=>OcultaBarra()}>
                        <Link to='#' className='menu-bars'>
                            <i className="bi bi-arrow-right-circle"></i>
                        </Link>
                    </ItemSimpleFixed> */}
            
                </ul>
            </nav> 


        </>

        
    )
}

export default Navegacion