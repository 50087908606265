import React, {useState, useEffect} from 'react'

import { get_DataPanel, get_DataDolar } from "../services/PanelAdmin";


import Loading from "../components/Loading/Loading";
import Navegacion from "../components/Navegacion/Navegacion";

import { TituloSeccion, ItemSeccion } from "../components/Titulos/Titulos";
import { ContentSeccion } from "../components/Content/Estilos";


import CardPanel from "../components/PanelAdmin/CardsPanel";
import CardsProductos from "../components/CardsProductos/CardsProductos";

import { get_Token } from "../helpers/getToken";

const Inicio = ({title}) => {

  const [columns, setColummns] = useState(false)
  const [loading, setLoading] = useState(true)
  const [usuarios, setUsuarios] = useState()
  const [lastProductos, setLastProductos] = useState()
  const [qProductos, setQProductos] = useState()
  const [valorDolar, setValorDolar] = useState()


  let token = "";


  useEffect(()=> {

    const getData = async(token) => {
      setLoading(true)
      token = get_Token()
      const data = await get_DataPanel(token)
      const dataDolar = await get_DataDolar()

      if(data){
        setUsuarios(data.UsuariosRegistrados)
        setQProductos(data.ProductosCargados)
        setLastProductos(data.UltimosProductosEditados)

      }

      if(dataDolar){
        setValorDolar(`${dataDolar.data.venta}`)
      } else {
        setValorDolar('Error al consultar')
      }


      setLoading(false)
    }

    getData(token)
  },[])


  if(loading){
    return(
      <Loading />
    )
  }

  return (
    <div className="row">

      <div className={!columns ? 'col-md-2 layout-general' : 'col-md-1 layout-general'}>
        <Navegacion hideSide={setColummns} active='/Inicio' />
      </div>

      <div className={!columns ? 'col-md-10 layout-general' : 'col-md-11 layout-general'}>
        <TituloSeccion title={title} />

        <ContentSeccion>
          <ItemSeccion title='Estadisticas.' />
          <div className="row mt-4 mb-4">
            <div className="col-md-4">
              <CardPanel titulo='Productos cargados' dato={qProductos} icono='bi bi-layers'/>


            </div>
            <div className="col-md-4">
              <CardPanel titulo='Usuarios registrados' dato={usuarios} icono='bi bi-people'/>
            </div>

            <div className="col-md-4">
              <CardPanel titulo='Cotizacion dolar' dato={valorDolar} icono='bi bi-currency-dollar'/>
            </div>
          </div>
          <hr />
          <br />
          <div className='mt-4'>          
            <ItemSeccion title='Ultimos Productos Modificados.' />
         
            {
              lastProductos.map(unProducto => (
                <CardsProductos producto={unProducto} key={unProducto.Id} />
              ))
            }

          </div>

        </ContentSeccion>
 
      </div>

    </div>
   
  )
}

export default Inicio