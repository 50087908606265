import React, {useState, useEffect} from 'react'
import { useParams } from "react-router-dom";

import { get_BannerImagen} from "../services/BannersImagen";

import Loading from "../components/Loading/Loading";
import Navegacion from "../components/Navegacion/Navegacion";
import { TituloSeccion, ItemSeccion } from "../components/Titulos/Titulos";
import { ContentSeccion} from "../components/Content/Estilos";

import SinDatos from "../components/Errors/SinDatos";
import UnBanner from "../components/CardsBanners/FormEditBannerImagen";
import { get_Token } from '../helpers/getToken';

const BannerImagenEditar = ({title}) => {
  
    const {id} = useParams()
    const [columns, setColummns] = useState(false)
    const [loading, setLoading] = useState(true)
    const [banner, setBanner] = useState(null)

    const token = get_Token()

    useEffect(()=>{
        const getData = async(id) => {
            const banner = await get_BannerImagen(id)
            setBanner(banner)
            setLoading(false)
        }

        getData(id)
    },[id])



    if(loading){
        return (
            <Loading />
        )
    }
    
    return (
        <div className="row">
            <div className={!columns ? 'col-md-2 layout-general' : 'col-md-1 layout-general'}>
                <Navegacion hideSide={setColummns} active='/Banners_imagen' />
            </div>
            <div className={!columns ? 'col-md-10 layout-general' : 'col-md-11 layout-general'}> 
                  
            <TituloSeccion title={title} />
  
            <ContentSeccion>
              <ItemSeccion title='Editar un Banner' />
              { banner === null ?
                <SinDatos title='Ups ...' message={`No encontramos datos asociados al banner de Texto Id Ref: ${id}`}   />
                :
                <UnBanner banner={banner} token={token} />
              }
            </ContentSeccion>
              
        </div>
  
      </div>
    )
}

export default BannerImagenEditar