import React, {useState, useEffect} from 'react'

import { getAll_BannersImagen } from "../services/BannersImagen";

import Navegacion from "../components/Navegacion/Navegacion";

import { TituloSeccion, ItemSeccion } from "../components/Titulos/Titulos";
import { ContentSeccion, LineSeparadora } from "../components/Content/Estilos";
import { CardSinDatos } from "../components/CardsBanners/Estilos";
import { CardSinBanners, CardBannerImagen } from "../components/CardsBanners/CardsBanners";
import Loading from "../components/Loading/Loading";

import { SecondaryButtonEditar } from "../components/Buttons/Buttons";
import { get_Token } from '../helpers/getToken';


const InitData = async()=>{
  const data = await getAll_BannersImagen()

  if(data){
    return data
  }

  return null
}

const BannersImagen = ({title}) => {

    const [columns, setColummns] = useState(false)
    const [loading, setLoading] = useState(true)
    const [activos, setActivos] = useState([])
    const [inactivos, setInactivos] = useState([])
    const [existBanner, setExistBanner] = useState(true)

   

    useEffect(()=> {
    
      const getData = async () => {
        get_Token()
        const data = await InitData()

        if(data.length === 0){
          setExistBanner(false)
        }

        if(data){
          setActivos(data.filter(unBanner => unBanner.Activo === true))
          setInactivos(data.filter(unBanner => unBanner.Activo === false)) 
        }else{
          setExistBanner(false)
        }

        setLoading(false)
      }

      getData()
    }, [])


    if(loading){
      return (
        <Loading />
      )
    }

    return (
      <div className="row">
  
        <div className={!columns ? 'col-md-2 layout-general' : 'col-md-1 layout-general'}>
          <Navegacion hideSide={setColummns} active='/Banners_imagen' />
        </div>
  
        <div className={!columns ? 'col-md-10 layout-general' : 'col-md-11 layout-general'}>
          <TituloSeccion title={title} />

          <ContentSeccion>

            { existBanner === false ?
              <>
                <CardSinBanners 
                  texto='Aún no posees nigún banner de tipo Imagen. Carga uno.'
                  link='/Banners_imagen/Agregar'
                  textoBtn='Agregar Banner'
                />
              </>
              :
              <>
                <div className="row mb-4">
                  <div className="col-md-3">
                    <ItemSeccion title='Banners Activos' />
                  </div>
                  <div className="col-md-3">
                    <SecondaryButtonEditar 
                      texto='Agregar Banner'
                      link='/Banners_imagen/Agregar'
                    />
                  </div>
                </div>

                {
                  activos.length === 0 ?
                  <>
                    <CardSinBanners 
                      texto='No posees banners de imagen activos. Carga uno.'
                      link='/Banners_imagen/Agregar'
                      textoBtn='Agregar Banner'
                    />
                  </>
                  :
                  <div className="row">
                    {
                      activos.map(banner => (
                        <CardBannerImagen unBanner={banner} key={banner.Id} />

                      ))
                    }
                  </div>
                }
                <LineSeparadora />

                <ItemSeccion title='Banners Inactivos' />

                { inactivos.length === 0 ?
                  <CardSinDatos>
                    <p>No posees Banner de Textos Inactivos.</p>
                  </CardSinDatos>
                  :
                  <>
                    <div className="row">
                      {
                        inactivos.map(banner =>(
                            <CardBannerImagen unBanner={banner} key={banner.Id} />
                        ))
                      }
                    </div>
                  </>
                }


              </>
            }

          </ContentSeccion>


        </div>
  
      </div>
     
    )

}

export default BannersImagen