import styled from 'styled-components';

export const ContentLogo = styled.div`
    background: var(--Color-Blanco);
    padding: 30px 10px;
    height: 15vh;
    border-bottom: 2px solid var(--Color-Negro);
    border-right: 2px solid var(--Color-Grey-Light) !important;

    & > img{
        width: 70%;
        margin-left: 20%!important;
    }


`

export const ItemExpand = styled.li`
    padding: 10px;
    width: 80%;
    margin-left: 10%;
    position:relative;
    
   
    color: var(--Color-Grey-Light) !important;

    & > a {
        color: var(--Color-Blanco);
        text-decoration: none;
        font-family: var(--Font-Display-Regular);
        font-size: 14px;
        transition: all .25s linear;
        
    }

    & > a > i{
        margin-right: 10px;
        transition: all .25s linear;
        font-size: 18px;
    }

    &:hover{
        transform: scale(1.1);

        & > a {
            text-decoration: underline var(--Color-Red-Primary);
            font-family: var(--Font-Display-Regular);
            color: var(--Color-Red-Primary);
        }

    }
`

export const ItemSimple = styled.li`
    padding: 10px;
    font-size: 18px;
    margin-left: -10%;
    width: 100%;
    position:relative;
    color: var(--Color-Grey-Light) !important;
    text-align: center;

    & > a {
        color: var(--Color-Blanco);
        text-decoration: none;
        font-family: var(--Font-Display-Regular);
        
    }

    & > a > i{
        margin-right: 10px;
        transition: all .25s linear;
    }

    &:hover{
        transform: scale(1.1);

        & > a {
            text-decoration: underline var(--Color-Red-Primary);
            font-family: var(--Font-Display-Regular);
            color: var(--Color-Red-Primary);
        }

    }
`

export const ItemSimpleFixed = styled.li`
    padding: 10px;
    font-size: 18px;
    margin-left: -10%;
    width: 100%;
    position:relative;
    color: var(--Color-Grey-Light) !important;
    text-align: center;

    & > a {
        color: var(--Color-Blanco);
        text-decoration: none;
        font-family: var(--Font-Display-Regular);
        
    }

    & > a > i{
        margin-right: 10px;
        transition: all .25s linear;
    }

    &:hover{
        transform: scale(1.1);

        & > a {
            text-decoration: underline var(--Color-Red-Primary);
            font-family: var(--Font-Display-Regular);
            color: var(--Color-Red-Primary);
        }

    }
`