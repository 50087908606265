import React from "react";

import { 
    CardSinBannerDiv, 
    CardTexto,
    TituloCardTexto,
    ShowBannerTexto
} from "./Estilos";

import { SecondaryButtonEditar } from "../Buttons/Buttons";

export const CardSinBanners = ({texto, link, textoBtn}) =>{
    return (
        <CardSinBannerDiv>
            <p>
                {texto}
            </p>
            <div className="row mt-4">
                <div className="col-md-6 mx-auto">
                    <SecondaryButtonEditar link={link} texto={textoBtn} />
                </div>
            </div>
            
        </CardSinBannerDiv>
    )
}

export const CardBannersTexto = ({unBanner}) => {
    return (
        <div className="col-md-6 mt-2 mb-2">
            <CardTexto>
                <div className="row mb-3">
                    <div className="col-md-9 align-self-center">
                        <TituloCardTexto>
                            <span>Ecommerce Id:</span> {unBanner.Id}
                        </TituloCardTexto>
  
                    </div>
                    <div className="col-md-3">
                      <SecondaryButtonEditar link={`/Banners_texto/Editar/${unBanner.Id}`} texto='Editar' />
                    </div>
                </div>

                <ShowBannerTexto style={{backgroundColor: `${unBanner.BackgroundColor}`}}>
                    <p>{unBanner.Texto}</p>
                </ShowBannerTexto>
            </CardTexto>
  
        </div>
    )
}

export const CardBannerImagen = ({unBanner}) => {
    return (
        <div className="col-md-6 mt-2 mb-2">
            <CardTexto>
                <div className="row mb-3">
                    <div className="col-md-9 align-self-center">
                        <TituloCardTexto>
                            <span>Ecommerce Id:</span> {unBanner.Id}
                        </TituloCardTexto>
                    </div>

                    <div className="col-md-3">
                      <SecondaryButtonEditar link={`/Banners_imagen/Editar/${unBanner.Id}`} texto='Editar' />
                    </div>

                    <img src={`https://api.ruedadigital.com.ar/${unBanner.URLImagen}`} className='mt-3 w-100' alt={`Banner ${unBanner.URLImagen}`} />

                </div>
            </CardTexto>
        </div>
    )
}