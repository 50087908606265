import React, {useState} from 'react'

import { add_BannerTexto } from "../../services/BannersTexto";

import { 
  LabelInput, 
  TextArea,
  DivTextEnd,
  SmallContadorAlert,
  SmallContadorAproved,
  LabelColorRojo,
  LabelColorNegro,
  LabelColorGris,
  ShowBannerTexto,
  ContentMsgError,
  ErrorValueInput
} from "./Estilos";

import Loading from "../Loading/Loading";
import { LineSeparadora } from "../Content/Estilos";
import { ButtonSumitCambios, ButtonCancelCambios } from "../Buttons/Buttons";

import ModalExito from "../Modals/ModalExito";
import ModalError from "../Modals/ModalError";

const FormAddBanner = ({token}) => {

  const [loading, setLoading] = useState(false)
  const [color, setColor] = useState('')
  const [texto, setTexto] = useState('')
  const [CaraceteresRestantes, setCaracteresRestantes] = useState(150)

  const [errorTexto, setErrorTexto] = useState(null)
  const [errorColor, setErrorColor] = useState(null)

  const [modalExito, setModalExito] = useState(false)
  const [modalError, setModalError] = useState(false)


  const checkTexto = (e) => {
    setTexto(e.target.value)
    const restantes = 151 - texto.length
    setCaracteresRestantes(restantes) 
    if(texto.length > 1){
      setErrorTexto(null)
    }

  }

  const checkColor = (e) => {
    setColor(e)
    setErrorColor(null)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(texto === ''){
      setErrorTexto('Este campo es obligatorio.')
      return
    } else if(color === ''){
      setErrorColor('Debes seleccionar un color.')
      return
    } else{
  
      setLoading(true)

      const body = {
        Texto: texto, 
        BackgroundColor: color
      }
      const data = await add_BannerTexto(token, body)

      if(data){
        setModalExito(true)
      }else{
        setModalError(true)
      }

      setLoading(false)
    }

  }

  if(loading){
    return (
      <Loading />
    )
  }

  return (
    <>
    <div className="row mt-4 mb-4">
        <div className="col-md-10">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="div mb-2 mb-2">
                <LabelInput
                  htmlFor='texto'
                >
                  Texto de Banner
                </LabelInput>
                {errorTexto != null && (
                  <ContentMsgError>
                    <ErrorValueInput className="bi bi-exclamation-triangle p-2 m-4">
                      <span className="p-2">
                        {errorTexto}
                      </span>
                    </ErrorValueInput>
                  </ContentMsgError>
                )}

                <TextArea
                  placeholder='Ingresa el texto del banner, longitud maxima 150 caracteres.'
                  id='texto'
                  onChange={(e)=>checkTexto(e)}
                  maxLength={150}
                />
                { CaraceteresRestantes > 50 ?
                  <DivTextEnd>
                      <SmallContadorAproved>
                        Carateres Restantes {CaraceteresRestantes}
                      </SmallContadorAproved>
                  </DivTextEnd>

                  :
                  <DivTextEnd>
                    <SmallContadorAlert>
                      Carateres Restantes {CaraceteresRestantes}
                    </SmallContadorAlert>
                  </DivTextEnd>


                }

              </div>
              <div className="div mb-2 mb-2">
                <LabelInput>
                  Color de Fondo
                </LabelInput>
                {errorColor != null && (
                  <ContentMsgError>
                    <ErrorValueInput className="bi bi-exclamation-triangle p-2 m-4">
                      <span className="p-2">
                        {errorColor}
                      </span>
                    </ErrorValueInput>
                  </ContentMsgError>
                )}

                <div  className='row mt-2 mb-2'>
                  <div className="col-md-4">
                    <input type="radio" value='#E42B31' id='Rojo' name='color' onChange={(e)=>checkColor(e.target.value)} />
                    <LabelColorRojo htmlFor='Rojo' />
                  </div>

                  <div className="col-md-4">
                    <input type="radio" value='#000000' id='Negro' name='color' onChange={(e)=>checkColor(e.target.value)} />
                    <LabelColorNegro htmlFor='Negro' />
                  </div>

                  <div className="col-md-4">
                    <input type="radio" value='#7F7F7F' id='Gris' name='color' onChange={(e)=>checkColor(e.target.value)} />
                    <LabelColorGris htmlFor='Gris' />
                  </div>

                </div>
                
              </div>
              <hr />
              <LabelInput>Asi se verá tu banner.</LabelInput>
                  <ShowBannerTexto style={{backgroundColor: `${color}`}}>
                    <p>{texto}</p>
                  </ShowBannerTexto>

              <LineSeparadora />

              <div className="row p-2">

                <div className="col-md-6">
                  <ButtonSumitCambios texto="Agregar Banner" type="submit"  />
                </div>
                <div className="col-md-6">
                  <ButtonCancelCambios texto="Cancelar" type="button" />
                </div>  
              </div>



              



            </form>
        </div>
    </div>

    <ModalExito 
    data={modalExito}
    icono='bi bi-check-circle'
    titulo='Todo ha ido bien.'
    message={`Se ha agregado un banner de Texto`}
    />

    <ModalError
        data={modalError}
        icono='bi bi-exclamation-triangle'
        titulo='Ups!'
        message={`Ah ocurrido un error al intentar realizar la operación solicitada.`}
    />
    </>
  )
}

export default FormAddBanner