import styled from 'styled-components';



export const TituloInfoOrden = styled.p`
    font-size: 14px;
    font-family: var(--Font-Display-Regular);
    color: var(--Color-Grey-Dark);
    margin-bottom: 0;
    margin-left: 5px;
`

export const ContentInfoOrden = styled.div`
    width: 90%;
    border-radius: 14px;
    background: var(--Color-Grey);
    padding: 10px;
    border: 1px solid #BABABA;
    font-size: 10px;
    color: var(--Color-Red-Primary);


    & > p {
        font-family: var(--Font-Display-Regular);
        color: var(--Color-Negro);
        font-size: 14px;
        margin-left: 5px;
        margin-bottom: 0;
        margin-top: 5px;

    }
`

export const TituloDetalleOrden = styled.p`
    font-size: 14px;
    font-family: var(--Font-Display-Regular);
    color: var(--Color-Negro);
    margin-bottom: 0;
`