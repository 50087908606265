import React from 'react'

const TitulosColumnaOrdenes = () => {
    return (
        <div className="row text-center tablaUsuario p-4">

            <div className="col-md-2">
                <p className="tituloColumnaUsuario">
                    Fecha
                </p>
            </div>

            <div className="col-md-2">
                <p className="tituloColumnaUsuario">
                    Cliente
                </p>
            </div>

            <div className="col-md-2">
                <p className="tituloColumnaUsuario">
                    N° Sicoi Orden
                </p>
            </div>

            <div className="col-md-2">
                <p className="tituloColumnaUsuario">
                    Total Orden
                </p>

            </div>


            <div className="col-md-2">
                <p className="tituloColumnaUsuario">
                    Tipo de Cambio
                </p>
            </div>


            <div className="col-md-2">
                <p className="tituloColumnaUsuario">
                    Más
                </p>
            </div>

        </div>
    )
}

export default TitulosColumnaOrdenes