import styled from 'styled-components';

export const ContentInfo = styled.div`
    background-color: var(--Color-Blanco);
    padding: 40px;
    border-radius: 15px;
    border: 1px solid #EBEBEB;
    box-shadow: 0px -1px 30px -4px rgba(127, 127, 127, 0.2);
`

export const TituloCard = styled.p`
    font-family: var(--Font-Roboto-Regular);
    font-weight: bold;
    font-size: 20px;
`

export const DatoCard = styled.p`
    font-family: var(--Font-Roboto-Regular);
    font-size: 20px;
    color: var(--Color-Red-Primary);
    margin-bottom: 0;
    & > i {
        font-size: 22px;
        margin-right: 10px;
    }
`