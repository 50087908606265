import React, {useState, useEffect} from 'react'

import { get_AllProductos } from "../services/Productos";

import { get_Token } from "../helpers/getToken";

import Loading from "../components/Loading/Loading";
import Navegacion from "../components/Navegacion/Navegacion";

import { TituloSeccion, ItemSeccion } from "../components/Titulos/Titulos";
import { ContentSeccion } from "../components/Content/Estilos";

import { ReturnButton } from "../components/Buttons/Buttons";
import { CardSinDatos } from "../components/CardsBanners/Estilos";

import TitulosColumnas from "../components/CardsProductos/TitulosColumnas";
import CardsProductos from "../components/CardsProductos/CardsProductos";
import Paginacion from "../components/CardsProductos/Pagination";
import { LabelInput, InputSearchUsuario } from "../components/CardsProductos/Estilos";


const Productos = ({title}) => {

  const [columns, setColummns] = useState(false)
  const [loading, setLoading] = useState(true)
  const [productos, setProductos] = useState([])
  const [filterProductos, setFilterProductos] = useState()
  
  const [searchNombre, setSearchNombre] = useState('')
  const [searchSicoi, setSearchSicoi] = useState('')


  
  const [showPaginado, setShowPaginado] = useState(true)
  const [qPaginas, setQPaginas] = useState([])

  const generaPaginado = (initArray) => {
    let qPaginas = Math.ceil(initArray.length/50)
    let paginas = [0]
  
    while (qPaginas !== 1) {
      const current = qPaginas -1
      qPaginas = current
      paginas.push(current)
    }

    paginas.sort(function(a, b){return a-b})

    setQPaginas(paginas)


    if(paginas.length === 1){
      setShowPaginado(false)
    }else{
      setShowPaginado(true)
    }

    return


  }

  const filtraProductos = () => {
    setLoading(true)

    let busquedaXNombre = []
    let busquedaXSicoi = []

    const data = []

    if(searchNombre === '' & searchSicoi === ''){
      const copyResult = [...productos]
      setFilterProductos(copyResult.splice(0,50))
      generaPaginado(copyResult)
    }else {

      if(searchNombre !== ''){
        busquedaXNombre = productos.filter(producto => producto.Nombre.includes(searchNombre))
        for (const unProducto of busquedaXNombre) {
          data.push(unProducto)
        }
      }

      if(searchSicoi !== ''){
        busquedaXSicoi = productos.filter(producto => producto.CodigoSicoi.includes(searchSicoi))
        for (const unProducto of busquedaXSicoi) {
          data.push(unProducto)
        }
      }

      const dataArr = new Set(data);
      const result = [...dataArr];
      generaPaginado(result)
      setFilterProductos(result)
    }



    setInterval(function () {setLoading(false)}, 1000);
    return
  }



  const cambiaPagina = (pagina) => {
    setLoading(true)
    const copyResult = [...productos]
    setFilterProductos(copyResult.splice(pagina*50,50))
    setInterval(function () {setLoading(false)}, 1000);
 
  }

  useEffect(() => {
    const getData = async () => {
      get_Token()
      const result = await get_AllProductos()
      if(result){
        const copyResult = [...result]
        generaPaginado(copyResult)
        setProductos(copyResult)
        setFilterProductos(result.splice(0,50))
 
      }else{
        setFilterProductos([])
      }
      setLoading(false)

    }

    getData()
  },[])

  if(loading){
    return(
      <Loading />
    )
  }

  return (
    
    <div className="row">
  
      <div className={!columns ? 'col-md-2 layout-general' : 'col-md-1 layout-general'}>
        <Navegacion hideSide={setColummns} active='/Productos' />
      </div>
  
      <div className={!columns ? 'col-md-10 layout-general' : 'col-md-11 layout-general'}>
        <TituloSeccion title={title} />

        <ContentSeccion>
          <>
          {
            productos.length === 0 ?
            <div className="mt-4 mb-4 ">
              <ItemSeccion title='Productos' />
              <CardSinDatos>
                <p>Aún no tienes productos cargados en tu plataforma. <br /> Contactate con tu proveedor de Sicoi.</p>
                <div className="row p-4">
                  <div className="col-md-4 mx-auto">
                    <ReturnButton />
                  </div>
                </div>
              </CardSinDatos>
            </div>
            :
            <>
              <div className="row mb-4 busqueda-Usuarios">
                <div className="col-md-3">
                  <LabelInput htmlFor="nombre">Filtrar por Nombre</LabelInput>
                  <InputSearchUsuario
                    onChange={(e)=>setSearchNombre(e.target.value)}
                    id='nombre'
                    placeholder="&#128269; Filtrar por Nombre"
                    value={searchNombre}
                  />
                </div>

                <div className="col-md-3">
                  <LabelInput htmlFor="sicoi">Filtrar por N° Sicoi</LabelInput>    
                  <InputSearchUsuario
                    onChange={(e)=>setSearchSicoi(e.target.value)}
                    id='sicoi'
                    placeholder="&#128269; Filtrar por N° Sicoi"
                    value={searchSicoi}
                  />
                </div>

                <div className="col-md-3">
                  {/* <LabelInput htmlFor="categoria">Filtrar por categoria</LabelInput>    
                  <InputSearchUsuario
                    // onChange={(e)=>setSearchSicoi(e.target.value)}
                    id='categoria'
                    placeholder="&#128269; Filtrar por N° Sicoi"
                  /> */}
                </div>



                <div className="col-md-3 align-self-end">
                  <button
                    type="button"
                    onClick={()=>filtraProductos()}
                    className='boton-filtrar'
                  >
                    Filtrar
                  </button>
                </div>
              </div>

              <ItemSeccion title='Productos' />

              { filterProductos.length > 0 ?
                <>
                  <TitulosColumnas />
 
                  {
                    filterProductos.map(unProducto => (
                      <CardsProductos producto={unProducto} key={unProducto.Id} />
                    ))
                  }
                  { showPaginado === true ?
                    <>
                      <hr />
                      <div className="row">
                        <div className="col-md-12">
                        <div className="row">
                          <Paginacion pagina={qPaginas} funcion={cambiaPagina} />
                        </div>
                        </div>

                        <div className="col-md-1"></div>
                      </div>
                    </>
                    :
                    <></>
                  }

                  
                
                </>
                :
                <>
                  <CardSinDatos>
                    <p>No hay resultados para tu busqueda.</p>
                    <div className="row p-4">
                      <div className="col-md-4 mx-auto">
                        <ReturnButton />
                      </div>
                    </div>
                  </CardSinDatos>
                </>

              }
            </>


          }
          
          </>
        </ContentSeccion>
        {/* {
          filterProductos.map(unProducto =>(
            <h1 key={unProducto.Id}>haY</h1>
          ))
        } */}


      </div>
  
    </div>
     
  )

}

export default Productos