import styled, {keyframes} from 'styled-components';


const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity:1;
  }
`;



export const LabelInput = styled.label`
  font-family: var(--Font-Display-Regular);
  font-size: 14px;
  color: var(--Color-Negro);
  margin-bottom: 5px;
  width: 100%;
`

export const ContentUnDatoUsuario = styled.div`
  width: 95%;
  margin: 2% 0;
  background: var(--Color-Grey);
  padding:20px;
  border-radius: 15px;

  &>p {
    color: var(--Color-Grey-Dark);
    margin-bottom: 0px;
    font-family: var(--Font-Display-SemiBold);
    font-size: 12px;
  }
`

export const InputSearchUsuario = styled.input`
  border: 1px solid rgba(186, 186, 186, 1);
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  transition: all .25s linear;
  box-shadow: none;

  &::placeholder{
    font-family: var(--Font-Display-Regular);
    font-size: 14px;
    color: var(--Color-Grey-Dark);
  }

  &:focus-visible{
    box-shadow: 1px 1px 1px 1px var(--Color-Red-Primary) !important;
    outline: 1px solid var(--Color-Red-Primary) ;
  }

`

export const SelectUsuario = styled.select`
  border: 1px solid rgba(186, 186, 186, 1);
  width: 100%;
  border-radius: 10px;
  padding: 13px;
  font-family: var(--Font-Display-Regular)!important;
  font-size: 14px !important;
  color: var(--Color-Grey-Dark);
  transition: all .25s linear;
  box-shadow: none;
  &:focus-visible{
    box-shadow: 1px 1px 1px 1px var(--Color-Red-Primary) !important;
    outline: 1px solid var(--Color-Red-Primary) ;
  }

`

export const ImagenProducto = styled.img`
  width: 50%;
  margin: auto;
`

export const LeyendaProductoSinImagen = styled.p`
  color: var(--Color-Red-Primary) !important;
  font-family: var(--Font-Display-SemiBold);
  font-size: 10px;
  width: 100%;
  text-align:center;
  margin-top: 5px;


`

export const InputDescripcionProductoReadOnly = styled.input`
  font-family: var(--Font-Display-Regular);
  width: 100%;
  border: none;
  padding: 15px;
  font-size: 14px;
  background: var(--Color-Grey-Light);
  border-radius: 14px;
`

export const InputDescripcionProducto = styled.input`
  font-family: var(--Font-Display-Regular);
  width: 100%;
  border: 1px solid var(--Color-Grey-Light) ;
  padding: 10px;
  border-radius: 14px;
  transition: all .25s linear;
  box-shadow: none;
  font-size: 14px;

  &::placeholder{
    font-family: var(--Font-Display-Regular);
    text-align: center;
    
  }

  &:focus-visible{
    box-shadow: 1px 1px 1px 1px var(--Color-Red-Primary) !important;
    outline: 1px solid var(--Color-Red-Primary) ;
  }
`

export const TextAreaDescripcionProducto = styled.textarea`
  font-family: var(--Font-Display-Regular);
  font-size: 14px;
  width: 100%;
  transition: all .25s linear;
  box-shadow: none;
  border: 1px solid var(--Color-Grey-Light);
  padding: 10px;
  border-radius: 14px;

  &::placeholder{
    font-family: var(--Font-Display-Regular);
    text-align: center;
    margin-top: 2%;
    
  }

  &:focus-visible{
    box-shadow: 1px 1px 1px 1px var(--Color-Red-Primary) !important;
    outline: 1px solid var(--Color-Red-Primary) ;
  }
`

export const SinImagen = styled.div `
    background: #FFFFFF;
    border: 1.33333px solid #EBEBEB;
    box-sizing: border-box;
    box-shadow: 0px -1.33333px 40px -5.33333px rgba(127, 127, 127, 0.26);
    border-radius: 20px;
    text-align: center;
    padding: 10px 0;

    & > i {
        font-size: 60px;
        color: var(--Color-Red-Primary);
    }

    & > p {
        font-size: 12px;
        color: var(--Color-Red-Primary);
        font-family: var(--Font-Display-Regular);
    }
`


export const LabelInputAddImagen = styled.label`
    background: var(--Color-Red-Primary);
    display: flex;
    justify-content: center;
    padding: 20px 10px;
    color: var(--Color-Blanco);
    width: 80%;
    margin: auto;
    border-radius: 10px;
    font-family: var(--Font-Display-Regular);
    transition: all .25s linear;
    font-size: 14px;
    &:hover{
        color: var(--Color-Red-Primary);
        background: var(--Color-Blanco);
    }
`

export const LeyendaAddImagen = styled.p`
    color: var(--Color-Grey-Dark);
    font-family: var(--Font-Display-Regular);
    font-size: 12px;
    margin: 1% 0;
    width: 90%;
    margin: 1% auto;

`

export const ContentMsgError = styled.div`
    animation: ${opacity} 1s ease;
    position: relative;
`

export const ErrorValueInput = styled.span`
    color: var(--Color-Red-Primary);
    font-family: var(--Font-Display-Regular);
    margin-left: 2%;
    font-weight:bold;
    font-size: 12px;
    
`

export const ContentImagenDeProducto = styled.div`
  border: 1px solid  var(--Color-Grey-Light);
  padding: 10px;

  & > img {
    width: 80%;
    margin-left: 10%;
  }
`

export const ButtonEliminaImagen = styled.button`
  width: 98%;
  margin-left: 1%;
  padding: 10px;
  background: var(--Color-Red-Primary);
  color: var(--Color-Blanco);
  font-family: var(--Font-Display-Regular);
  font-size: 14px;
  border: 1px solid var(--Color-Blanco);
  border-radius: 14px;
  transition: all .25s linear;

  &:hover{
    color: var(--Color-Red-Primary);
    background: var(--Color-Blanco);
  }

`

export const ButtonVerImagen = styled.button`
  width: 98%;
  margin-left: 1%;
  padding: 10px;
  background: var(--Color-Grey-Dark);
  color: var(--Color-Blanco);
  font-family: var(--Font-Display-Regular);
  font-size: 14px;
  border: 1px solid var(--Color-Blanco);
  border-radius: 14px;
  transition: all .25s linear;

  &:hover{
    color: var(--Color-Grey-Dark);
    background: var(--Color-Blanco);
  }

`
