import React from 'react';


import { TituloInfoOrden, ContentInfoOrden, TituloDetalleOrden } from "./Estilos";

const CardUnaOrden = ({ orden }) => {
    console.log(orden)
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return (
        <>
            <div className="row mt-4">
                <div className="col-md-12 mb-3">
                    <TituloInfoOrden>Datos de la Orden</TituloInfoOrden>
                </div>
                <div className="col-md-3 mb-3">
                    <ContentInfoOrden>
                        Fecha Orden
                        <p>{new Date(orden.Orden.CreatedAt).toLocaleDateString("es-ES", options)}</p>
                    </ContentInfoOrden>
                </div>
                <div className="col-md-3 mb-3">
                    <ContentInfoOrden>
                        Total Orden
                        <p><small>ARS $</small> {orden.Orden.TotalOrden}</p>
                    </ContentInfoOrden>
                </div>
                <div className="col-md-3 mb-3">
                    <ContentInfoOrden>
                        Tipo de Cambio
                        <p> <small>ARS $</small> {orden.Orden.TipoCambio}</p>
                    </ContentInfoOrden>
                </div>
                <div className="col-md-3 mb-3">
                    <ContentInfoOrden>
                        N° Sicoi
                        <p>{orden.Orden.OrdenSicoi !== '' ? orden.Orden.OrdenSicoi : 'Sin asignar'}</p>
                    </ContentInfoOrden>

                </div>
                <div className="col-md-3 mb-3">
                    <ContentInfoOrden>
                        Estado Pago
                        <p>{orden.Orden.EstadoPago}</p>
                    </ContentInfoOrden>
                </div>

                <div className="col-md-12">
                    <hr />
                </div>



            </div>
            <div className="row mt-4">
                <div className="col-md-12 mb-3">
                    <TituloInfoOrden>Datos del Cliente</TituloInfoOrden>
                </div>
                <div className="col-md-3 mb-3">
                    <ContentInfoOrden>
                        Nombre
                        <p>{orden.Orden.Cliente.Nombre} {orden.Orden.Cliente.Apellido}</p>
                    </ContentInfoOrden>
                </div>
                <div className="col-md-3 mb-3">
                    <ContentInfoOrden>
                        Correo Electronico
                        <p>{orden.Orden.Cliente.CorreoElectronico}</p>
                    </ContentInfoOrden>
                </div>
                <div className="col-md-3 mb-3">
                    <ContentInfoOrden>
                        Telefono
                        <p>{orden.Orden.Cliente.Telefono}</p>
                    </ContentInfoOrden>

                </div>
                <div className="col-md-3 mb-3">
                    <ContentInfoOrden>
                        Registrado desde:
                        <p>{new Date(orden.Orden.Cliente.CreatedAt).toLocaleDateString("es-ES", options)}</p>
                    </ContentInfoOrden>
                </div>

                <div className="col-md-2 mb-3">
                    <ContentInfoOrden>
                        CUIL
                        <p>{orden.Orden.ClientePerfil.CUIL}</p>
                    </ContentInfoOrden>
                </div>

                <div className="col-md-5 mb-3">
                    <ContentInfoOrden>
                        Razon Social
                        <p>{orden.Orden.ClientePerfil.RazonSocial} </p>
                    </ContentInfoOrden>
                </div>

                <div className="col-md-5 mb-3">
                    <ContentInfoOrden>
                        Domicilio
                        <p>{orden.Orden.ClientePerfil.Domicilio}</p>
                    </ContentInfoOrden>
                </div>

                <div className="col-md-3 mb-3">
                    <ContentInfoOrden>
                        Condicion Frente al IVA
                        <p>{orden.Orden.ClientePerfil.CondicionFrenteAlIVA.CondicionFrenteAlIva}</p>
                    </ContentInfoOrden>
                </div>

                <div className="col-md-3 mb-3">
                    <ContentInfoOrden>
                        N° Sicoi
                        <p>{orden.Orden.ClientePerfil.NSicoi}</p>
                    </ContentInfoOrden>
                </div>

                <div className="col-md-12">
                    <hr />
                </div>



            </div>
            <div className="row mt-4">
                <div className="col-md-12 mb-3">
                    <TituloInfoOrden>Datalle de la Orden</TituloInfoOrden>
                </div>
                    <div className="row">
                        <div className="col-md-3">
                            <TituloDetalleOrden>Producto</TituloDetalleOrden>
                            
                        </div>
                        <div className="col-md-2">
                            <TituloDetalleOrden>Cantidad</TituloDetalleOrden>
                        </div>
                        <div className="col-md-3">
                            <TituloDetalleOrden>Precio Unitario</TituloDetalleOrden>
                            
                        </div>
                        <div className="col-md-2">
                            <TituloDetalleOrden>Codigo Sicoi</TituloDetalleOrden>
                            
                        </div>
                        <div className="col-md-2">
                            <TituloDetalleOrden>Metros Estandard</TituloDetalleOrden>
                            
                        </div>
                        <div className="col-md-12">
                            <hr />
                        </div>
                    </div>


                    {   orden.DetallesOrden.map(unDetalle => (
                        <div className="row mt-2 mb-2" key={unDetalle.Id}>
                            <div className="col-md-3">
                                <TituloDetalleOrden>
                                    {unDetalle.Producto.Nombre}
                                </TituloDetalleOrden>
                            </div>
                            <div className="col-md-2">
                                <TituloDetalleOrden>
                                    {unDetalle.Cantidad} <small>rollo/s</small>
                                </TituloDetalleOrden>
                            </div>
                            <div className="col-md-3">
                                <TituloDetalleOrden>
                                    <small>USD</small> {unDetalle.PrecioUnitario}
                                </TituloDetalleOrden>
                                
                            </div>
                            <div className="col-md-2">
                                <TituloDetalleOrden>
                                    {unDetalle.Producto.CodigoSicoi}
                                </TituloDetalleOrden>
                            </div>
                            <div className="col-md-2">
                                <TituloDetalleOrden>
                                    {unDetalle.Producto.MetrosEstandard}
                                </TituloDetalleOrden>
                            </div>
                        </div>
                    ))

                    }





            </div>




        </>

    );
}



export default CardUnaOrden;