import styled from 'styled-components';

export const ContentMsgError = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
`

export const CardMensaje = styled.div`
    background: var(--Color-Blanco);
    box-shadow: 0px -1px 30px -4px rgba(127, 127, 127, 0.26);
    border-radius: 24px;
    padding: 50px;

    & > img {
        width: 50%;
    }

    & > h2 {
        font-family: var(--Font-Roboto-Black);
        font-size: 30px;
        letter-spacing: 2px;
        margin: 0.5rem 0;
        color: var(--Color-Red-Primary);
        padding: 20px 40px 20px 0px;
    }

    & > p {
        font-family: var(--Font-Display-Regular);
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 0.2px;
        margin-bottom: 10px;
    }

    @media (max-width: 480px) {
        & > h2{
            font-size: 16px;
            margin: 0;
            padding: 20px 0px 10px;
        }

        & > p{
            margin-top: 0;
            font-size: 12px;
        }
    }
`