import React, { useState } from 'react'
import Cookies from "universal-cookie";
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { login_Adm } from "../../services/Login";

import Loading from "../Loading/Loading";

import {
    ContentGralLoginForm,
    CardFormLogin,
    ImagenLogo,
    CapsulaPanel,
    ContentTitulosSubtitulo,
    LabelForm,
    ContentMsgError,
    ErrorValueInput,
    BotonIngresar
} from "./Estilos";

import ModalError from "../Modals/ModalError";


const cookies = new Cookies();

const LoginForm = ({ logo }) => {

    const [loading, setLoading] = useState(false)
    const [modalError, setModalError] = useState(false)

    if(loading){
        return (
            <Loading />
        )
    }


    return (
        <>
            <ContentGralLoginForm>
                <CardFormLogin>
                    <div>
                        <ImagenLogo src={logo} alt='Rueda Digital' />
                    </div>

                    <div className='mt-4 mb-4'>
                        <CapsulaPanel>
                            Panel de Administrador
                        </CapsulaPanel>
                    </div>

                    <ContentTitulosSubtitulo>
                        <h1>
                            Bienvenido
                        </h1>
                        <p>
                            Para ingresar al panel del administrador debes iniciar sesión. <br /> Completa tus datos de ingreso para poder hacerlo.
                        </p>
                    </ContentTitulosSubtitulo>

                    <div className="mt-2 mb-2">
                        <Formik
                            initialValues={
                                {
                                    usuario: '',
                                    pass: ''
                                }
                            }

                            validate={(valores) => {
                                let errores = {}

                                if (!valores.usuario) {
                                    errores.usuario = ' Este campo no puede estar vacio.'
                                }

                                if (!valores.pass) {
                                    errores.pass = ' Este campo no puede estar vacio.'
                                }

                                return errores
                            }}

                            onSubmit={async (valores) => {
                                setLoading(true)
                                const data = {
                                    Username: valores.usuario,
                                    Password: valores.pass
                                }
                                const result = await login_Adm(data)

                                if(result){
                                    cookies.set("admin_rueda", result , { path: "/" });
                                    window.location.href = "./Inicio";
                                }else{
                                    setModalError(true)
                                }
                                setLoading(false)

                            }}
                        >

                            {
                                ({ errors }) => (
                                    <Form>
                                        <div className="row">
                                            <div className="col-md-12 mt-2 mb-2 mx-auto">
                                                <LabelForm htmlFor='usuario'>
                                                    Usuario
                                                </LabelForm>

                                                <Field
                                                    type="text"
                                                    name='usuario'
                                                    id='usuario'
                                                    className='Rueda_InputInicio'
                                                    placeholder='Ingresá tu usuario'
                                                />

                                                <ErrorMessage name='usuario' component={() => (
                                                    <ContentMsgError>
                                                        <ErrorValueInput className='bi bi-exclamation-triangle p-1'>
                                                            {errors.usuario}
                                                        </ErrorValueInput>
                                                    </ContentMsgError>

                                                )} />



                                            </div>

                                            <div className="col-md-12 mt-2 mb-2 mx-auto">
                                                <LabelForm htmlFor='pass'>
                                                    Contraseña
                                                </LabelForm>

                                                <Field
                                                    type="password"
                                                    name='pass'
                                                    id='pass'
                                                    className='Rueda_InputInicio'
                                                    placeholder='Ingresá tu contraseña'
                                                />
                                                <ErrorMessage name='pass' component={() => (
                                                    <ContentMsgError>
                                                        <ErrorValueInput className='bi bi-exclamation-triangle p-1'>
                                                            {errors.pass}
                                                        </ErrorValueInput>
                                                    </ContentMsgError>

                                                )} />



                                            </div>
                                            <div className="col-md-12 mt-2 mx-auto">
                                                <BotonIngresar type='submit'>
                                                    Ingresar
                                                </BotonIngresar>
                                            </div>
                                        </div>
                                    </Form>
                                )


                            }

                        </Formik>

                    </div>




                </CardFormLogin>

            </ContentGralLoginForm>

        
            <ModalError
                data={modalError}
                icono='bi bi-exclamation-triangle'
                titulo='Ups!'
                message={`Los datos ingresados son incorrectos.`}
            />
        </>

    )
}

export default LoginForm