import axios from "axios";
import { EndPointApi, Credencials } from "./config";

export const getAll_Clientes = async(token) => {
    try {
        const {data} = await axios({
            method:'GET',
            url:`${EndPointApi}/Clientes/getAll`,
            auth: Credencials,
            headers:{
                "Auth-Token": token,
            }
        })

        return data.data
        
    } catch (error) {
        return null
    }
}

export const get_PerfilCliente = async(token, hash) => {
    try {
        const result = await axios({
            method:'GET',
            url:`${EndPointApi}/PerfilCliente/ByCliente/${hash}`,
            auth: Credencials,
            headers:{
                "Auth-Token": token,
            }
        })
        if(result){
            return result.data.data
        }

        return null
        
    } catch (error) {
        return null
    }
}

export const get_ClienteByMail = async(token, mail) => {
    try {
        const {data} = await axios({
            method:'GET',
            url:`${EndPointApi}/Clientes/getOne/${mail}`,
            auth: Credencials,
            headers:{
                "Auth-Token": token,
            }
        })

        if(data.status === 'OK'){
            return data.data
        }

        return null
        
    } catch (error) {
        return null
    }
}