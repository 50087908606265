import styled from 'styled-components';

export const ContentButtonPrimary = styled.div `
    width: 100%;
`

export const RedPrimaryButtonStyles = styled.button`
    background: var(--Color-Red-Primary);
    color: var(--Color-Blanco);
    font-family: var(--Font-Display-Regular);
    padding: 20px 15px;
    width: 90%;
    margin-left: 5%;
    border-radius: 10px;
    border: none;
    transition: all .25s linear;
    &:hover{
        background: var(--Color-Blanco);
        color: var(--Color-Red-Primary);
        transform: scale(.9);
        font-family: var(--Font-Display-Bold);
    }

`

export const ContentButtonSecondary = styled.div `
    width: 100%;
    position: relative;
`

export const SecondaryButtonStyles = styled.button`
    background: var(--Color-Blanco);
    color: var(--Color-Red-Primary);
    border: 1px solid var(--Color-Red-Primary);
    font-family: var(--Font-Display-Regular);
    padding: 10px;
    width: 70%;
    margin-left: 15%;
    border-radius: 10px;
    transition: all .25s linear;
    &:hover{
        border: 1px solid var(--Color-Blanco);
        transform: scale(.9);
        font-family: var(--Font-Display-Bold);
    }
`

export const SecondaryButtonEditarStyles = styled.button`
    background: var(--Color-Blanco);
    color: var(--Color-Red-Primary);
    border: 1px solid var(--Color-Red-Primary);
    font-family: var(--Font-Display-Regular);
    padding: 5px 15px;
    width: 100%;
    border-radius: 10px;
    transition: all .25s linear;
    font-size: 16px;
    font-weight: normal;
    &:hover{
        border: 1px solid var(--Color-Blanco);
        transform: scale(.9);
        font-weight: bold;
    }
`

export const SecondaryButtonIrAlInicioStyles = styled.button`
    background: var(--Color-Blanco);
    color: var(--Color-Red-Primary);
    border: 1px solid var(--Color-Red-Primary);
    font-family: var(--Font-Display-Regular);
    padding: 15px;
    width: 60%;
    border-radius: 10px;
    transition: all .25s linear;
    &:hover{
        border: none;
        transform: scale(.9);
        font-family: var(--Font-Display-Bold);
    }
`

export const ConfirmButtonStyles = styled.button`
    background: var(--Color-Red-Primary);
    color: var(--Color-Blanco);
    font-family: var(--Font-Display-Regular);
    padding: 10px;
    width: 90%;
    margin-left: 5%;
    border-radius: 10px;
    border: none;
    transition: all .25s linear;
    &:hover{
        background: var(--Color-Blanco);
        color: var(--Color-Red-Primary);
        transform: scale(.9);
        font-weight: bold;
    }

`

export const CancelButtonStyles = styled.button`
    background: var(--Color-Blanco);
    color: var(--Color-Negro);
    font-family: var(--Font-Display-Bold);
    padding: 10px;
    width: 90%;
    margin-left: 5%;
    border-radius: 10px;
    border: 1px solid #fff;
    transition: all .25s linear;
    &:hover{
        border: 1px solid var(--Color-Negro);
        transform: scale(.9);
        font-family: var(--Font-Display-Bold);
    }
`

export const ActivaElementosStyles = styled.button`
    background: var(--Color-Green-Aproved);
    color: var(--Color-Blanco);
    font-family: var(--Font-Display-Bold);
    padding: 10px;
    width: 90%;
    margin-left: 5%;
    border-radius: 10px;
    border: 1px solid var(--Color-Blanco);
    transition: all .25s linear;
    &:hover{
        border: 1px solid var(--Color-Green-Aproved);
        background: var(--Color-Blanco);
        color: var(--Color-Green-Aproved);
        font-family: var(--Font-Display-Bold);
    }
`

export const PausaElementosStyles = styled.button`
    background: var(--Color-Yellow-Paused);
    color: var(--Color-Blanco);
    font-family: var(--Font-Display-Bold);
    padding: 10px;
    width: 90%;
    margin-left: 5%;
    border-radius: 10px;
    border: 1px solid var(--Color-Blanco);
    transition: all .25s linear;
    &:hover{
        border: 1px solid var(--Color-Yellow-Paused);
        background: var(--Color-Blanco);
        color: var(--Color-Yellow-Paused);
        font-family: var(--Font-Display-Bold);
    }
`

export const DeleteElementosStyles = styled.button`
    background: var(--Color-Negro);
    color: var(--Color-Blanco);
    font-family: var(--Font-Display-Bold);
    padding: 10px;
    width: 90%;
    margin-left: 5%;
    border-radius: 10px;
    border: 1px solid var(--Color-Blanco);
    transition: all .25s linear;
    &:hover{
        border: 1px solid var(--Color-Negro);
        background: var(--Color-Blanco);
        color: var(--Color-Negro);
        font-family: var(--Font-Display-Bold);
    }
`