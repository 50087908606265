import React from 'react';


import {  OpcionButton } from "../Buttons/Buttons";

const CardOrden = ({orden}) => {

    const options = { year: 'numeric', month: 'long', day: 'numeric' };


    return (
        <div className="row text-center p-4">

            <div className="col-md-2 align-self-center">
                <p className="tituloColumnaUsuario">
                    {new Date(orden.CreatedAt).toLocaleDateString("es-ES", options)}
                </p>
            </div>

            <div className="col-md-2 align-self-center">
                <p className="tituloColumnaUsuario">
                    {orden.Cliente.Nombre} {orden.Cliente.Apellido}
                </p>
            </div>

            <div className="col-md-2 align-self-center">
                <p className="tituloColumnaUsuario">
                    {orden.OrdenSicoi === '' ? 'Sin asignar' : orden.OrdenSicoi }
                </p>
            </div>

            <div className="col-md-2 align-self-center">
                <p className="tituloColumnaUsuario">
                    ARS $ {orden.TotalOrden}
                </p>

            </div>


            <div className="col-md-2 align-self-center">
                <p className="tituloColumnaUsuario">
                    ARS $ {orden.TipoCambio}
                </p>
            </div>


            <div className="col-md-2 align-self-center">

                <OpcionButton
                    link={`/Orden/${orden.HashOrden}`}
                    texto='Ver Orden'
                />

            </div>

        </div>
    );
}



export default CardOrden;