import React from 'react';

import { ContentInfo, TituloCard, DatoCard } from "./Estilos";

const CardsPanel = ({titulo, icono, dato}) => {
    return (
        <ContentInfo>
            <TituloCard>{titulo}</TituloCard>
            <DatoCard>
                <i className={icono}></i>
                {dato}
            </DatoCard>
        </ContentInfo> 
    )

}



export default CardsPanel;