import React from 'react'

import { ItemSeccion } from "../Titulos/Titulos";
import { LineSeparadora } from "../Content/Estilos";

import FormEdit from "./FormEditCategoria";

import { TituloInfoCategoria, ContentInfoCategoria } from "./Estilos";


const UnaCategoria = ({categoria, token}) => {

  return (
        <>
            <ItemSeccion title='Nombre de la Categoria' />

            <div className="row mt-4">
                <div className="col-md-2">
                    <TituloInfoCategoria>
                        Ecommerce Id
                    </TituloInfoCategoria>

                    <ContentInfoCategoria>
                        <p>
                            {categoria.Id}
                        </p>

                    </ContentInfoCategoria>
                </div>
                <div className="col-md-2">
                    <TituloInfoCategoria>
                        N° Sicoi
                    </TituloInfoCategoria>
                    <ContentInfoCategoria>
                        <p>
                            {categoria.IdentificacionSicoi}
                        </p>
                    </ContentInfoCategoria>
                </div>
                <div className="col-md-5">
                    <TituloInfoCategoria>
                        Nombre Categoria
                    </TituloInfoCategoria>
                    <ContentInfoCategoria>
                        <p>
                            {categoria.Nombre}
                        </p>
                    </ContentInfoCategoria> 
                </div>



            </div>

            <LineSeparadora />

            <ItemSeccion title='Imagen Categoria' />

            <FormEdit categoria={categoria} token={token} />
        </>
  )
}

export default UnaCategoria