import styled  from 'styled-components';



export const LabelInput = styled.label`
  font-family: var(--Font-Display-Regular);
  font-size: 14px;
  color: var(--Color-Negro);
  margin-bottom: 5px;
`

export const ContentUnDatoUsuario = styled.div`
  width: 95%;
  margin: 2% 0;
  background: var(--Color-Grey);
  padding:20px;
  border-radius: 15px;

  &>p {
    color: var(--Color-Grey-Dark);
    margin-bottom: 0px;
    font-family: var(--Font-Display-SemiBold);
    font-size: 12px;
  }
`

export const InputSearchUsuario = styled.input`
  border: 1px solid rgba(186, 186, 186, 1);
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  transition: all .25s linear;
  box-shadow: none;

  &::placeholder{
    font-family: var(--Font-Display-Regular);
    font-size: 14px;
    color: var(--Color-Grey-Dark);
  }

  &:focus-visible{
    box-shadow: 1px 1px 1px 1px var(--Color-Red-Primary) !important;
    outline: 1px solid var(--Color-Red-Primary) ;
  }

`

export const SelectUsuario = styled.select`
  border: 1px solid rgba(186, 186, 186, 1);
  width: 100%;
  border-radius: 10px;
  padding: 13px;
  font-family: var(--Font-Display-Regular)!important;
  font-size: 14px !important;
  color: var(--Color-Grey-Dark);
  transition: all .25s linear;
  box-shadow: none;
  &:focus-visible{
    box-shadow: 1px 1px 1px 1px var(--Color-Red-Primary) !important;
    outline: 1px solid var(--Color-Red-Primary) ;
  }

`

