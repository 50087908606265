import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const Paginacion = ({pagina, funcion}) => {


    return(
        <Pagination aria-label="Page navigation example" className='col-md-1'>


            {
                pagina.map(unaPagina => (
                    <PaginationItem key={unaPagina}>
                        <PaginationLink onClick={() => funcion(unaPagina)}>
                            {unaPagina+1}
                        </PaginationLink>
                    </PaginationItem>
                ))
            }


      </Pagination>
    )
}

export default Paginacion