import axios from "axios";
import { EndPointApi, Credencials } from "./config";

export const get_AllOrdenes = async (token) => {
    try {
        const {data} = await axios({
            method:'GET',
            url:`${EndPointApi}/Ordenes/getAll`,
            auth: Credencials,
            headers:{
                "Auth-Token": token,
            }
        })

        return data.data
        
    } catch (error) {
        return null
    }
}

export const get_OrdenByHash = async(token, hash) => {
    try {
        const result = await axios({
            method:'GET',
            url: `${EndPointApi}/Orden/${hash}`,
            auth: Credencials,
            headers:{
                "Auth-Token": token,
            }
        })

        if(result){
            return result.data.data
        }

        return null

        
    } catch (error) {
        return null
    }
}