import styled from 'styled-components';

export const ContentModal = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, .5) cover;
   width: 100%;
`
export const ContentMensajeExito = styled.div`
    background: var(--Color-Blanco);
    padding: 50px;
    border-radius: 14px;
    width: 100%;
    border: 1px solid var(--Color-Green-Aproved);
    text-align: center;

    & > div > i {
        font-size: 100px;
        color: var(--Color-Green-Aproved)
    }

    & > div > h2 {
        font-size: 20px;
        font-family: var(--Font-Display-Regular);
        color: var(--Color-Green-Aproved)
    }

    
    & > div > p {
        font-size: 20px;
        font-family: var(--Font-Display-Regular);
        color: var(--Color-Negro);
        width: 80%;
        margin-left: 10%;
    }

`

export const ContentMensajeError = styled.div`
    background: var(--Color-Blanco);
    padding: 50px;
    border-radius: 14px;
    width: 100%;
    border: 1px solid var(--Color-Red-Primary);
    text-align: center;

    & > div > i {
        font-size: 100px;
        color: var(--Color-Red-Primary)
    }

    & > div > h2 {
        font-size: 20px;
        font-family: var(--Font-Display-Regular);
        color: var(--Color-Red-Primary)
    }

    
    & > div > p {
        font-size: 20px;
        font-family: var(--Font-Display-Regular);
        color: var(--Color-Negro);
        width: 80%;
        margin-left: 10%;
    }

`