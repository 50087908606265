import React, {useState} from 'react'
import { activeCategoria  } from "../../services/Categorias";

import Loading from "../Loading/SeccionLoading";

import { ContentFormActiveCategoria, EstadoActivo, EstadoInactivo } from "./Estilos";
import { ActivaElemento, InactivaElemento } from "../Buttons/Buttons";

import ModalExito from "../Modals/ModalExito";
import ModalError from "../Modals/ModalError";



const FormActiveCategoria = ({categoria}) => {
    const [loading, setloading] = useState(false)
    const [modalExito, setModalExito] = useState(false)
    const [modalError, setModalError] = useState(false)
    
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI5YTI2ZmMzOC05YTY4LTExZWMtYjkwOS0wMjQyYWMxMjAwMDIiLCJpYXQiOjE2NDc2MjEwMDd9.foR8YXAiMemU1rPSqBBppZdETjPhTAe-3gJykClCmtM'
    
    const active = async (token)=> {
        setloading(true)
        const data = await activeCategoria(categoria.Id, token)
        setloading(false)
        if(data === true){

            setModalExito(true)
        }else{
            setModalError(true)
        }
        
    }


    return (
        <>
            <ContentFormActiveCategoria className='row'>
                { loading === true ?
                    <div className="col-md-12">
                        <Loading />
                    </div>
                :
                    <>
                        <div className="col-md-6 text-center align-self-center">

                            { categoria.Activo === true ?
                                <EstadoActivo>
                                    <span>Estado: </span> Activa
                                </EstadoActivo>
                            :
                                <EstadoInactivo>
                                    <span>Estado: </span> Pausada
                                </EstadoInactivo>
                            }
                        </div>
                        <div className="col-md-6">
                            { categoria.Activo === true ?
                                <InactivaElemento 
                                    type="button"
                                    icono='bi bi-pause-btn'
                                    texto='Pausar'
                                    funcion={active}
                                    parametro={token}
                                />
                            :
                                <ActivaElemento
                                    icono='bi bi-play'
                                    texto='Activar'
                                    funcion={active}
                                    parametro={token}

                                />
                            }

                        </div>
                    </>
                }
            </ContentFormActiveCategoria>

            <ModalExito 
                data={modalExito}
                icono='bi bi-check-circle'
                titulo='Todo ha ido bien.'
                message={`Se ha ${categoria.Activo === true ? 'Pausado' : 'Activado'} la categoria ${categoria.Nombre} de forma correcta`}
            />

            <ModalError
                data={modalError}
                icono='bi bi-exclamation-triangle'
                titulo='Ups!'
                message={`Ah ocurrido un error al intentar realizar la operación solicitada.`}
            />
        </>

    )
}

export default FormActiveCategoria