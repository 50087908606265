import React from 'react'


import { 
    CardCategoria,
    CardSinCategorias,
    ImagenCategoria,
    TituloSicoi,
    SicoiCategoria,
    NombreCategoria
} from "./Estilos";

import { SecondaryButtonEditar } from "../Buttons/Buttons";

export const CardsCategorias = ({categoria}) => {
  return (
    <div className='col-md-4 mt-2 mb-2'>
        <CardCategoria className="row">
            <div className="col-md-6">
                {
                    categoria.URLImagen === '' ?
                    <ImagenCategoria>
                        <i className="bi bi-camera"></i>
                        <p>Sin Imagen</p>
                    </ImagenCategoria>

                    :
                    <div>
                        <img src={`https://api.ruedadigital.com.ar/${categoria.URLImagen}`} className='w-100' alt={categoria.Nombre} />
                    </div>
                }
                
            </div>
            <div className="col-md-6 align-self-center">
                <TituloSicoi>
                    Codigo Sicoi
                </TituloSicoi>
                <SicoiCategoria>
                    # {categoria.IdentificacionSicoi}
                </SicoiCategoria>

                <NombreCategoria>                
                    {categoria.Nombre}
                </NombreCategoria>
                <br />
                <SecondaryButtonEditar 
                    link={`/Categoria/Editar/${categoria.Id}`}
                    texto='Editar'
                />

            </div>
        </CardCategoria>
        
    </div>
  )
}

export const SinCategorias = ({texto}) => {
    return (
        <CardSinCategorias>
            <p>
                {texto}
            </p>
        </CardSinCategorias>

       
    )
}
