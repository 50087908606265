import axios from "axios";
import { EndPointApi, Credencials } from "./config";

// Obtiene todos los Banners
export const getAll_BannersTexto = async () => {
    try {
        const {data} = await axios({
            method:'GET',
            url: `${EndPointApi}/bannersTexto/getAll`,
            auth: Credencials
        })

        return data.data
        
    } catch (error) {
        return null
    }
}

// Agrega un Banner de Texto
export const add_BannerTexto = async (token, body) => {
    try {
        const {data} = await axios({
            method:'POST',
            url: `${EndPointApi}/bannersTexto/add`,
            auth: Credencials,
            headers:{
                "Auth-Token": token
            },
            data: body
        })

        if(data.status === 'OK'){
            return true
        }

        return null
        
    } catch (error) {
        return null
    }
}

// Obtiene un Banner de Texto
export const get_BannerTexto = async(id) => {
    try {
        const {data} = await axios({
            method:'GET',
            url: `${EndPointApi}/bannersTexto/getOne/${id}`,
            auth: Credencials
        })

        if(data.status === 'OK'){
            return data.data
        }

        return null


    } catch (error) {
        return null
    }
}

// Activa / Desactiva un Banner de Texto
export const active_BannerTexto = async(id, token) => {
    try {
        const {data} = await axios({
            method:'PUT',
            url: `${EndPointApi}/bannersTexto/activeOne/${id}`,
            auth: Credencials,
            headers:{
                "Auth-Token": token
            }
        })

        if(data.status === 'OK'){
            return true
        }

        return null
        
    } catch (error) {
        return null
    }
}

// Edita un Banner de Texto
export const edit_BannerTexto = async(id, token, body) => {
    try {
        const {data} = await axios({
            method:'PUT',
            url: `${EndPointApi}/bannersTexto/editOne/${id}`,
            auth: Credencials,
            data: body,
            headers:{
                "Auth-Token": token
            }

        })

        if(data.status === 'OK'){
            return true
        }

        return null
        
    } catch (error) {
        return null
    }
}

// Elimina un Banner de Texto
export const delete_BannerTexto = async(id) => {
    try {
        const {data} = await axios({
            method:'DELETE',
            url: `${EndPointApi}/bannersTexto/deleteOne/${id}`,
            auth: Credencials,
        })

        if(data.status === 'OK'){
            return true
        }

        return null
        
    } catch (error) {
        return null
    }
}