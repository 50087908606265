import styled from 'styled-components';

export const ContentTituloSeccion = styled.div`
    background: var(--Color-Blanco);
    padding: 30px 10px;
    height: 15vh;
    border-bottom: 2px solid var(--Color-Grey-Light);
    display: flex;
    align-items: center;

    & > h1 {
        font-family: var(--Font-Display-SemiBold);
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0px;
        margin-left: 20px;
    }

    & > div > h1 {
        font-family: var(--Font-Display-SemiBold);
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0px;
        margin-left: 20px;
    }

    & > div > h1 > span {
        font-family: var(--Font-Display-Thin);
        font-size: 15px;
        color: var(--Color-Grey-Dark);
        margin-right: 2%;
        letter-spacing: 2px;
    }



`

export const ItemSeccionStyle = styled.p`
    font-family: var(--Font-Display-Regular);
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
`

export const LineItemSeccion = styled.div`
    width: 7%;
    height: 1px;
    background: var(--Color-Grey-Dark);
`